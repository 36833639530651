import React, { useState } from 'react';
import { Grid, TextField, Button, Box, Typography } from '@material-ui/core';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import { makeStyles } from '@material-ui/core/styles';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import { Formik, Form } from 'formik';
import ImageUploadInput from '../../shared/ImageUploadFormInput';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  configurationStyling: {
    height: '24rem',
    overflowY: 'scroll'
  }
}));

export default function CoreStyling({ initialValues, onSubmit }) {
  const [formFields, setFormFields] = useState(initialValues);
  const history = useHistory();
  const classes = useStyles();
  const [styles, setStyles] = useState(initialValues?.styles || '');

  const handleConfiguration = (code) => {
    setStyles(code);
  };

  return (
    <Grid container spacing={4} direction='column'>
      <Grid item xs={12}>
        <Formik initialValues={formFields} onSubmit={onSubmit}>
          {({ values, handleBlur, handleChange }) => {
            values.styles = styles;
            return (
              <Form>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <Typography variant='h6' gutterBottom>
                      Styling
                    </Typography>
                    <div className={classes.configurationStyling}>
                      <Editor
                        value={styles}
                        padding={10}
                        onValueChange={handleConfiguration}
                        highlight={(code) =>highlight(code, languages.plaintext) }
                        style={{
                          fontFamily: 'monospace',
                          fontSize: 17
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid container item xs={12} spacing={6}>
                    <Grid item xs={12} md={6}>
                      <ImageUploadInput title='Desktop logo' required fieldName={'desktopLogo.imageUrl'} />
                      <TextField
                        fullWidth
                        name='desktopLogoAltTag'
                        id='desktopLogoAltTag'
                        label='Alt Tag'
                        value={values.desktopLogoAltTag || values.desktopLogo?.altTag}
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ImageUploadInput title='Mobile Logo' required fieldName={'mobileLogo.imageUrl'} />
                      <TextField
                        fullWidth
                        name='mobileLogoAltTag'
                        id='mobileLogoAltTag'
                        label='Alt Tag'
                        value={values.mobileLogoAltTag || values.mobileLogo?.altTag}
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ImageUploadInput title='Favicon' required fieldName={'favicon.imageUrl'} />
                      <TextField
                        fullWidth
                        name='faviconAltTag'
                        id='faviconAltTag'
                        label='Alt Tag'
                        value={values.faviconAltTag || values.favicon?.altTag}
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ImageUploadInput title='Group logo' required fieldName={'groupLogo.imageUrl'} />
                      <TextField
                        fullWidth
                        name='groupLogoAltTag'
                        id='groupLogoAltTag'
                        label='Alt Tag'
                        value={values.groupLogoAltTag || values.groupLogo?.altTag}
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container justifyContent='flex-end' xs={12}>
                  <Box mr={3}>
                    <Button onClick={() => history.push('/templates')}>Cancel</Button>
                  </Box>
                  <Button type='submit' variant='contained' color='primary'>
                    Save
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
