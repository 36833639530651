import React, { useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Category from "@material-ui/icons/Category";
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import CarRentalIcon from '@mui/icons-material/CarRental';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LanguageIcon from '@mui/icons-material/Language';
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { AuthContext } from '../../shared/context/AuthContext';
import PaletteIcon from '@mui/icons-material/Palette';
import { Build, Group } from "@material-ui/icons";
import { ROLE_ACCOUNT_MANAGER, ROLE_ADMIN, ROLE_CONTENT, ROLE_DEALER, ROLE_DEALER_ADMIN } from "../../shared/Constants";

const useStyles = makeStyles((theme) => ({
    listItem: {
        '&:hover': {
            backgroundColor: '#012F56',
            color: 'white',
            '& $listItemIcon': {
                color: 'white',
            },
        },
    },
    selectedItem: {
        backgroundColor: '#012F56',
        color: 'white',
        '& $listItemIcon': {
            color: 'white',
        },
    },
    listItemIcon: {
        color: 'inherit',
    },
    listItemText: {
        color: 'inherit',
    },
}));

export default function MenuItems() {
    const classes = useStyles();
    const { role } = useContext(AuthContext);
    const location = useLocation();

    // Check if the user has access to a specific menu item based on their role
    const hasAccess = (allowedRoles) => allowedRoles.some(r => r === role);

    const isSelected = (path) => location.pathname === path;

    return (
        <div style={{ color: 'black' }}>
            {/* Dashboard */}
            {hasAccess([ROLE_DEALER, ROLE_DEALER_ADMIN, ROLE_ACCOUNT_MANAGER, ROLE_CONTENT, ROLE_ADMIN]) &&
                <ListItem
                    button
                    key="Dashboard"
                    component={RouterLink}
                    to="/dashboard"
                    className={`${classes.listItem} ${isSelected('/dashboard') ? classes.selectedItem : ''}`}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <QueryStatsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" className={classes.listItemText} />
                </ListItem>
            }
            {/* Configurator */}
            {hasAccess([ROLE_ADMIN, ROLE_CONTENT]) &&
                <ListItem
                    button
                    key="Configurator"
                    component={RouterLink}
                    to="/DASH"
                    className={`${classes.listItem} ${isSelected('/DASH') ? classes.selectedItem : ''}`}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Configurator" className={classes.listItemText} />
                </ListItem>
            }
            {/* Vehicle Library */}
            {hasAccess([ROLE_ADMIN, ROLE_CONTENT]) &&
                <ListItem
                    button
                    key="VehicleLibrary"
                    component={RouterLink}
                    to="/VehicleLibrary/Makes"
                    className={`${classes.listItem} ${isSelected('/VehicleLibrary/Makes') ? classes.selectedItem : ''}`}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <DirectionsCar />
                    </ListItemIcon>
                    <ListItemText primary="Vehicle Library" className={classes.listItemText} />
                </ListItem>
            }
            {/* Manage Vehicles */}
            {hasAccess([ROLE_ADMIN, ROLE_CONTENT]) &&
                <ListItem
                    button
                    key="ManageVehicles"
                    component={RouterLink}
                    to="/ManageVehicles"
                    className={`${classes.listItem} ${isSelected('/ManageVehicles') ? classes.selectedItem : ''}`}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <CarRentalIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Vehicles" className={classes.listItemText} />
                </ListItem>
            }
            {/* Dealers */}
            {hasAccess([ROLE_DEALER_ADMIN, ROLE_ACCOUNT_MANAGER, ROLE_CONTENT, ROLE_ADMIN]) &&
                <ListItem
                    button
                    key="Dealers"
                    component={RouterLink}
                    to="/dealers"
                    className={`${classes.listItem} ${isSelected('/dealers') ? classes.selectedItem : ''}`}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <BusinessCenterIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dealers" className={classes.listItemText} />
                </ListItem>
            }
            {/* Slides */}
            {hasAccess([ROLE_DEALER_ADMIN, ROLE_ACCOUNT_MANAGER, ROLE_CONTENT, ROLE_ADMIN]) &&
                <ListItem
                    button
                    key="Slides"
                    component={RouterLink}
                    to="/slides"
                    className={`${classes.listItem} ${isSelected('/slides') ? classes.selectedItem : ''}`}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <AddToQueueIcon />
                    </ListItemIcon>
                    <ListItemText primary="Slides" className={classes.listItemText} />
                </ListItem>
            }
            {/* Offers */}
            {hasAccess([ROLE_DEALER_ADMIN, ROLE_ACCOUNT_MANAGER, ROLE_CONTENT, ROLE_ADMIN]) &&
                <ListItem
                    button
                    key="Offers"
                    component={RouterLink}
                    to="/offers"
                    className={`${classes.listItem} ${isSelected('/offers') ? classes.selectedItem : ''}`}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <LocalOfferIcon />
                    </ListItemIcon>
                    <ListItemText primary="Offers" className={classes.listItemText} />
                </ListItem>
            }
            {/* Categories */}
            {hasAccess([ROLE_DEALER_ADMIN, ROLE_ACCOUNT_MANAGER, ROLE_CONTENT, ROLE_ADMIN]) &&
                <ListItem
                    button
                    key="Categories"
                    component={RouterLink}
                    to="/categories"
                    className={`${classes.listItem} ${isSelected('/categories') ? classes.selectedItem : ''}`}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <Category />
                    </ListItemIcon>
                    <ListItemText primary="Categories" className={classes.listItemText} />
                </ListItem>
            }
            {/* Motorgroups */}
            {hasAccess([ROLE_ADMIN]) &&
                <ListItem
                    button
                    key="Motorgroups"
                    component={RouterLink}
                    to="/motorgroups"
                    className={`${classes.listItem} ${isSelected('/motorgroups') ? classes.selectedItem : ''}`}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <Group />
                    </ListItemIcon>
                    <ListItemText primary="Motorgroups" className={classes.listItemText} />
                </ListItem>
            }
            {/* Websites */}
            {hasAccess([ROLE_ADMIN]) &&
                <ListItem
                    button
                    key="Websites"
                    component={RouterLink}
                    to="/websites"
                    className={`${classes.listItem} ${isSelected('/websites') ? classes.selectedItem : ''}`}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <LanguageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Websites" className={classes.listItemText} />
                </ListItem>
            }
            {/* Templates */}
            {hasAccess([ROLE_ADMIN]) &&
                <ListItem
                    button
                    key="Templates"
                    component={RouterLink}
                    to="/templates"
                    className={`${classes.listItem} ${isSelected('/templates') ? classes.selectedItem : ''}`}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <PaletteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Templates" className={classes.listItemText} />
                </ListItem>
            }
            {hasAccess([ROLE_ADMIN]) &&
                <ListItem
                    button
                    key="Template Forms"
                    component={RouterLink}
                    to="/templateForms"
                    className={`${classes.listItem} ${isSelected('/templateForms') ? classes.selectedItem : ''}`}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <PaletteIcon />
                    </ListItemIcon>
                    <ListItemText primary="Template Forms" className={classes.listItemText} />
                </ListItem>
            }
            {/* Builds */}
            {hasAccess([ROLE_ADMIN, ROLE_CONTENT]) &&
                <ListItem
                    button
                    key="Builds"
                    component={RouterLink}
                    to="/builds"
                    className={`${classes.listItem} ${isSelected('/builds') ? classes.selectedItem : ''}`}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <Build />
                    </ListItemIcon>
                    <ListItemText primary="Builds" className={classes.listItemText} />
                </ListItem>
            }
        </div>
    );
}
