import React, { useState, useContext, useEffect } from 'react';
import { Box, Stepper, Step, StepButton, Button, Typography, Toolbar, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { BASE_URI } from '../../shared/Constants';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import CoreStyling from './CoreStyling';
import Configuration from './Configuration';
import ColourStyling from './ColourStyling';
import Menu from './Menu';
import ButtonStyling from './ButtonStyling';
import Preview from './Preview';
import { LoadingContext } from '../../shared/context/loadingContext';
import { DealerContext } from '../../shared/DealerContext';
import Fonts from './Fonts';

const TemplateInfo = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isAddMode, setIsAddMode] = useState(!id);
  const { dealerId } = useContext(DealerContext);
  const [formValues, setFormValues] = useState(null);
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    stepPadding: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
  }));

  const classes = useStyles();

  const handleSubmit = async (values) => {
    setFormValues({ ...formValues, ...values });

    // Add validation before submission
    if (!dealerId) {
      enqueueSnackbar('Cannot proceed: no dealer selected', { variant: 'warning' });
      return;
    }

    const processLogo = (logo, altTag) => {
      if (!logo) return { imageUrl: '', altTag: '' };
      const imageUrl = logo.imageUrl?.image || logo.imageUrl || '';
      return {
        imageUrl,
        altTag: altTag || logo.altTag || '',
      };
    };

    values.desktopLogo = processLogo(values.desktopLogo, values.desktopAltTag);
    values.mobileLogo = processLogo(values.mobileLogo, values.mobileLogoAltTag);
    values.groupLogo = processLogo(values.groupLogo, values.groupLogoAltTag);
    values.favicon = processLogo(values.favicon, values.faviconAltTag);
    values.siteImage = processLogo(values.siteImage, values.siteImageAltTag);
    values.dealerId = dealerId;

    // Either create or update template
    if (isAddMode) {
      await createTemplate(values);
    } else {
      await editTemplate(id, values);
    }
  };

  const createTemplate = async (fields) => {
    try {
      showLoading();
      const filteredFields = { ...fields };
      delete filteredFields.dealer;
      const result = await axios.post(`${BASE_URI}/WebsiteTemplates`, {
        ...filteredFields,
      });
      
      enqueueSnackbar('Successfully added the template.', { variant: 'success' });
      // Save to related dealers if relatedDealerIDs is not empty
      if (fields.relatedDealerIDs && fields.relatedDealerIDs.length > 0) {
        saveToRelatedDealers(result?.data.id, fields);
      }

      setIsAddMode(false);
      history.push('/templates');
      return result;
    } catch (error) {
      enqueueSnackbar(`Failed to add template: ${error.message}`, { variant: 'error' });
    } finally {
      hideLoading();
    }
  };

  const editTemplate = async (id, fields) => {
    try {
      showLoading();
      const result = await axios.put(`${BASE_URI}/WebsiteTemplates/${id}`, fields);
      enqueueSnackbar('Successfully saved the template changes.', { variant: 'success' });
      // Save to related dealers if relatedDealerIDs is not empty
      if (fields.relatedDealerIDs && fields.relatedDealerIDs.length > 0) {
        saveToRelatedDealers(id, fields);
      }
      return result;
    } catch (error) {
      enqueueSnackbar(error.data?.message, { variant: 'error' });
    } finally {
      hideLoading();
    }
  };

  const getTemplate = async () => {
    try {
      showLoading();
      const result = await axios.get(`${BASE_URI}/WebsiteTemplates/${id}`);
      let formData = result.data;
      formData.isAddMode = isAddMode;
      setFormValues({
        ...formData,
      });
    } catch (error) {
      enqueueSnackbar('Unable to get template details', { variant: 'error' });
      hideLoading();
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      getTemplate().then(() => {
        hideLoading();
      });
    }
  }, []);

  async function saveToRelatedDealers(id, template) {
    try {
      const result = await axios.post(`${BASE_URI}/WebsiteTemplates/UpdateRelatedDealers/${id}`, template);
      enqueueSnackbar('Template saved to linked dealers', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to save the template to the linked dealers', { variant: 'error' });
    }
  }

  const steps = isAddMode
    ? null
    : ['Configuration', 'Core Styling', 'Fonts', 'Colour Styling', 'Menu', 'Button Styling', 'Preview'];
 
  const stepsContent = isAddMode
    ? [
        <Configuration initialValues={formValues} onSubmit={handleSubmit} isAddMode={isAddMode} />,
      ]
    : [
        <Configuration initialValues={formValues} onSubmit={handleSubmit} isAddMode={isAddMode} />,
        <CoreStyling initialValues={formValues} onSubmit={handleSubmit} />,
        <Fonts />,
        <ColourStyling initialValues={formValues} onSubmit={handleSubmit} />,
        <Menu initialValues={formValues} onSubmit={handleSubmit} />,
        <ButtonStyling initialValues={formValues} onSubmit={handleSubmit} />,
        <Preview initialValues={formValues} onSubmit={handleSubmit} />,
      ];

  return (
    <Paper className={classes.paper}>
      <Toolbar disableGutters>
        <Typography variant='h6' style={{ flex: '1 1' }} component='div' gutterBottom>
          {isAddMode ? 'Add' : 'Edit'} Template
        </Typography>
      </Toolbar>
      <Stepper nonLinear activeStep={activeStep}>
        {steps && steps.map((label, index) => (
          <Step key={index}>
            <StepButton
              color="inherit"
              onClick={() => {
                if (!isAddMode || index === 0) setActiveStep(index); 
              }}
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      <div>
        <div>
          <Typography sx={{ mt: 2, mb: 1 }}>{stepsContent[activeStep]}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {activeStep !== 0 && (
              <Button
                color='inherit'
                disabled={activeStep === 0}
                onClick={() => setActiveStep((prev) => prev - 1)}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
            )}
          </Box>
        </div>
      </div>
    </Paper>
  );
};

export default TemplateInfo;
