import React, { useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Grid,
  Button,
  Box,
  Switch,
  FormControlLabel,
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextField as TextFieldFormik } from 'formik-material-ui';
import { BASE_URI } from '../../../shared/Constants';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { LoadingContext } from '../../../shared/context/loadingContext';
import { DealerContext } from '../../../shared/DealerContext';
import { useHistory, useParams } from 'react-router-dom';

const initialValues = {
  headline: false,
  vehicleDetails: false,
  images:false,
  priceRetail:false,
  priceRepayment:false,
  dealerInfo:false,
  enquiryForm:false,
  financeCalculator:false,
  extendedData: false,

  priceRepaymentType:'',
  imageViewType:'',
  extendedDataType:'',

  //text fields
  headlineText: '',
  imageText:'',
  vehicleDetailsText:'',
  priceRetailText:'',
  priceRepaymentText:'',
  dealerInfoText:'',
  enquiryFormText: '',
  extendedDataText:'',
  financeCalculatorText:'',

  includePage: false,
  pageTitle: '',
  pageDescription: '',
  url: '',
  htmlModule1:false,
  htmlModule2:false,
  title: '',
  description: '',
  htmlModule1Text:'',
  htmlModule2Text:'',

};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  dynamicComponentCell: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const UsedVDP = (props) => {
  const { page } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedMotorGroup } = useContext(DealerContext);
  const parsedObj = page?.templateConfig ? JSON.parse(page.templateConfig) : initialValues;
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { id } = useParams();
  let history = useHistory();

  const priceRepaymentTypes = [
    { id: 1, name: 'Section' },
    { id: 2, name: 'Stacked' }
  ];

  const imagesView = [
    { id: 1, name: 'Window' },
    { id: 2, name: 'Slider' }
  ];

  const extendedData = [
    { id: 1, name: 'List' },
    { id: 2, name: 'Accordion' }
  ];

  const handleSelectChange = (e, setFieldValue, fieldName) => {
    setFieldValue(fieldName, e.target.value);
  };

  const onSubmitUpdatePage = async (fields) => {
    try {
      showLoading();

      fields.bobPayload = transformToBobPayload(fields);

      if (selectedMotorGroup.id) {
        if (page != undefined || page != null) {
          const pageId = page.id;
          page.templateConfig = JSON.stringify(fields);
          page.url = fields.url;
          page.title = fields.title;
          page.description = fields.description;
          page.content = fields.content;
          axios
            .put(`${BASE_URI}/WebsitePages/${pageId}`, {
              ...page,
              id: pageId
            })
            .then((result) => {
              enqueueSnackbar('Updated Used VDP page', { variant: 'success' });
              hideLoading();
            })
            .catch((error) => {
              enqueueSnackbar('Operation failed', { variant: 'error' });
              hideLoading();
            });
        } else {
          const newPage = {
            url: fields.url,
            title: fields.title,
            description: fields.description,
            content: '',
            templateConfig: JSON.stringify(fields),
            dealerID: selectedMotorGroup.id,
            websiteTemplatesId: parseInt(id),
            pageCategoryId: 6
          };
          axios
            .post(`${BASE_URI}/WebsitePages`, {
              ...newPage
            })
            .then((result) => {
              enqueueSnackbar('created used VDP page', { variant: 'success' });
              history.push('/templates');
              hideLoading();
            })
            .catch((error) => {
              enqueueSnackbar('Operation failed', { variant: 'error' });
              hideLoading();
            });
        }
      } else {
        enqueueSnackbar('Select a dealer first', { variant: 'warning' });
        hideLoading();
      }
    } catch (error) {
      enqueueSnackbar('Operation failed', { variant: 'error' });
      hideLoading();
    }
  };

  const transformToBobPayload = (values) => {
    const layoutMap = new Map();
  
    const addToLayoutMap = (partial, order, includes, params = []) => {
      if (includes || params.length > 0) {
        if (!layoutMap.has(partial)) {
          layoutMap.set(partial, { partial, order, includes, type: 'default', params });
        } else {
          const existing = layoutMap.get(partial);
          existing.includes = existing.includes || includes;
          existing.params = [...new Map([...existing.params, ...params].map(item => [item.key, item])).values()];
        }
      }
    };
  
    Object.keys(values).forEach((field) => {
      if (['includePage', 'title', 'description', 'url', 'bobPayload'].includes(field)) {
        return;
      }
  
      const includes = values[field] ? true : false;
      const order = values[`${field}Order`] || 0;
      let params = [];
  
      if (!field.endsWith('Text') && !field.endsWith('Order')) {
        if (values[`${field}Text`] !== undefined) {
          params.push({ key: `${field}Text`, value: values[`${field}Text`] });
        }
        addToLayoutMap(field, order, includes, params);
      }
    });
  
    const layout = Array.from(layoutMap.values());
  
    return {
      layout,
      page: page?.title || ''
    };
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar>
        <Typography variant='h6' component='div'>
          Used Vehicles Details Page
        </Typography>
      </Toolbar>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Formik
            initialValues={parsedObj}
            onSubmit={(values) => {
              onSubmitUpdatePage(values);
            }}
          >
            {({ touched, values, errors, handleBlur, handleChange, setFieldValue }) => {
              const handleChangeCustom = (fieldName, checked) => {
                setFieldValue(fieldName, checked);
              };
              return (
                <Form>
                  <TableContainer className={classes.tableContainer}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Module</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Include</TableCell>
                          <TableCell>Content</TableCell>
                          <TableCell>Order</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      <TableRow>
                          <TableCell>Include Page</TableCell>
                          <TableCell>Boolean</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.includePage}
                                  onChange={(e) => handleChangeCustom('includePage', e.target.checked)}
                                  name='includePage'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Title</TableCell>
                          <TableCell>Text</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='title'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell>Text</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='description'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>URL Link</TableCell>
                          <TableCell>Link</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='urlText'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Headline</TableCell>
                          <TableCell>Section</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.headline}
                                  onChange={(e) => handleChangeCustom('headline',e.target.checked)}
                                  name='headline'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.headline && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='headlineText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='headlineOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Price (Retail) </TableCell>
                          <TableCell>Section</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.price}
                                  onChange={(e) => handleChangeCustom('price',e.target.checked)}
                                  name='price'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.price && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='priceText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='priceOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Price (Repayment)</TableCell>
                          <TableCell>
                          <TextField
                              id='priceRepaymentType'
                              name='priceRepaymentType'
                              label='Price Repayment'
                              value={values.priceRepaymentType}
                              select
                              fullWidth
                              helperText={errors.priceRepaymentType && touched.priceRepaymentType ? errors.priceRepaymentType : ''}
                              error={errors.priceRepaymentType && touched.priceRepaymentType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'priceRepaymentType')}
                            >
                              {priceRepaymentTypes.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.priceRepayment}
                                  onChange={(e) => handleChangeCustom('priceRepayment',e.target.checked)}
                                  name='priceRepayment'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.priceRepayment && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='priceRepaymentText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='priceRepaymentOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Images</TableCell>
                          <TableCell>
                          <TextField
                              id='imageViewType'
                              name='imageViewType'
                              label='Image View'
                              value={values.imageViewType}
                              select
                              fullWidth
                              helperText={errors.imageViewType && touched.imageViewType ? errors.imageViewType : ''}
                              error={errors.imageViewType && touched.imageViewType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'imageViewType')}
                            >
                              {imagesView.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.images}
                                  onChange={(e) => handleChangeCustom('images',e.target.checked)}
                                  name='images'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.images && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='imagesText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='imagesOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Vehicle Details</TableCell>
                          <TableCell>List</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.vehicleDetails}
                                  onChange={(e) => handleChangeCustom('vehicleDetails',e.target.checked)}
                                  name='vehicleDetails'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.vehicleDetails && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='vehicleDetailsText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='vehicleDetailsOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Extended Data</TableCell>
                          <TableCell>
                          <TextField
                              id='extendedDataType'
                              name='extendedDataType'
                              label='Extended Data View'
                              value={values.extendedDataType}
                              select
                              fullWidth
                              helperText={errors.extendedDataType && touched.extendedDataType ? errors.extendedDataType : ''}
                              error={errors.extendedDataType && touched.extendedDataType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'extendedDataType')}
                            >
                              {extendedData.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.extendedData}
                                  onChange={(e) => handleChangeCustom('extendedData',e.target.checked)}
                                  name='extendedData'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.extendedData && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='extendedDataText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='extendedDataOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Dealer Info</TableCell>
                          <TableCell>Section</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.dealerInfo}
                                  onChange={(e) => handleChangeCustom('dealerInfo',e.target.checked)}
                                  name='dealerInfo'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.dealerInfo && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='dealerInfoText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='dealerInfoOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Enquiry Form</TableCell>
                          <TableCell>Section</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.enquiryForm}
                                  onChange={(e) => handleChangeCustom('enquiryForm',e.target.checked)}
                                  name='enquiryForm'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.enquiryForm && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='enquiryFormText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='enquiryFormOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Finance Calculator</TableCell>
                          <TableCell>Section</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.financeCalculator}
                                  onChange={(e) => handleChangeCustom('financeCalculator',e.target.checked)}
                                  name='financeCalculator'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.financeCalculator && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='financeCalculatorText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='financeCalculatorOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>HTML Module 1</TableCell>
                          <TableCell>HTML</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.htmlModule1}
                                  onChange={(e) => handleChangeCustom('htmlModule1', e.target.checked)}
                                  name='htmlModule1'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.htmlModule1 && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='htmlModule1Text'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='htmlModule1Order'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>HTML Module 2</TableCell>
                          <TableCell>HTML</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.htmlModule2}
                                  onChange={(e) => handleChangeCustom('htmlModule2', e.target.checked)}
                                  name='htmlModule2'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.htmlModule2 && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='htmlModule2Text'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='htmlModule2Order'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={2}>
                    <Button variant='contained' color='primary' type='submit'>
                      Save Changes
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UsedVDP;
