import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MotorgroupForm from "./MotorgroupForm";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#3378af",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

export default function MotorgroupContainer() {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = React.useState(0);

    const { id } = useParams();
    const [isAddMode, setIsAddMode] = useState(!id);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <AppBar position="relative" className={classes.root}>
                <Tabs
                    variant="scrollable"
                    value={selectedTab != null ? selectedTab : setSelectedTab(0)}
                    onChange={handleChange}
                    textColor="inherit"
                >
                    <Tab label={isAddMode ? "Add Motorgroup" : "Edit Motorgroup"}/>
                </Tabs>
            </AppBar>
            {selectedTab === 0 && <MotorgroupForm/>}
        </>
    );
}
