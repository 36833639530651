import React, {useState, useEffect, useRef} from "react";
import RefreshIcon from '@material-ui/icons/Refresh';
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

export default function FormActions({back, reset}) {
  return (
    <Grid item style={{marginTop: '2rem'}} container justify='flex-end' xs={12}>
      {
        back &&
        <Box mr={3}>
          <Button
            color="secondary"
            onClick={back}
          >
            Back
          </Button>
        </Box>
      }
      <Box mr={3}>
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          color="primary"
          onClick={reset}
        >
          Reset
        </Button>
      </Box>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        color="primary"
        type="submit"
        // disabled={!props.isValid}
      >
        Save
      </Button>
    </Grid>
  )
}