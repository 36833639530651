import React, { useEffect, useState, useRef, useContext } from 'react';
import { Grid, TextField, Button, Box, FormControlLabel, Toolbar, MenuItem, Checkbox, CircularProgress } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import { Switch } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { BASE_URI } from '../../shared/Constants';
import { useSnackbar } from 'notistack';
import { LoadingContext } from '../../shared/context/loadingContext';
import { DealerContext } from '../../shared/DealerContext';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import ImageUploadInput from '../../shared/ImageUploadFormInput';
import FileUploadInput from '../../shared/FileUploadInput';
import ImageService from '../../shared/ImageService';

import { DatePicker } from 'formik-material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
//
// import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  formControl: {
    margin: theme.spacing(3)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  tr: {
    padding: '5px 30px'
  },
  th: {
    padding: '5px 10px',
    textAlign: 'center'
  },
  td: {
    padding: '2px 10px'
  },
  tdCenter: {
    padding: '2px 10px',
    textAlign: 'center'
  }
}));

function getStyles(arrayItem, selectArray, theme) {
  return {
    fontWeight: selectArray.indexOf(arrayItem) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  };
}

export default function SlidesForm() {
  const classes = useStyles();
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);
  const { dealerId, selectedMotorGroup: selectedDealer } = useContext(DealerContext);
  const [motorGroupDealers, setMotorGroupDealers] = useState([]);
  const [relatedDealerIDs, setRelatedDealerIDs] = useState([]);
  const [relatedDealers, setRelatedDealers] = useState([]);
  const [isLoadingDealers, setIsLoadingDealers] = useState(true);
  const history = useHistory();
  const { id } = useParams();
  const [slideId, setSlideId] = useState(id);
  const [isAddMode, setIsAddMode] = useState(!id);
  const theme = useTheme();
  const mountedRef = useRef(true);
  const [makeArr, setMakeArr] = useState([]);
  const [dwsArr, setDwsArr] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const initialValues = {
    name: '',
    make: '',
    makeId: '',
    active: false,
    category: '',
    url: '',
    slideImageUrl: '',
    mobileImageUrl: '',
    isVideo: false,
    buttonText: '',
    cta: '',
    startDate: new Date(),
    endDate: new Date(),
    dealerWebsites: [],
    gws: [],
    isAddMode: isAddMode,
    altTag: '',
    mobileAltTag: ''
  };
  const [formFields, setFormFields] = useState(initialValues);

  const gwsArr = ['Renault Zambez', 'KIA Table View', 'Renault Toka', 'Renault Virtual', 'Renault South Africa'];

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250
      }
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getSlide = async () => {
      try {
        showLoading();
        const result = await axios.get(`${BASE_URI}/slide/${id}`, {
          cancelToken: source.token
        });
        let formData = result.data;
        formData.gws = [];
        //formData.isAddMode = isAddMode;
        if (formData.isVideo) {
          let videoFileUrlSplit = formData.slideImageUrl.split('/');
          let fullFileName = videoFileUrlSplit[videoFileUrlSplit.length - 1];
          let fileNameSplit = fullFileName.split('.');

          formData.slideVideoFile = new File(['No contents'], fileNameSplit[0], { type: `video/${fileNameSplit[1]}` });
        }

        setFormFields(formData);
        setRelatedDealerIDs(result.data?.relatedDealerIDs);
      } catch (error) {
        enqueueSnackbar('Unable to get slide details', { variant: 'error' });
        // addAlert('Unables to load vehicle categories')
        //history.push('..')
      }
    };

    const getMakeArr = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/makes `, {
          cancelToken: source.token
        });

        setMakeArr(result.data.list);
        return result.data.list;
      } catch (error) {
        if (axios.isCancel(error)) return;
        // enqueueSnackbar("Unable to get vehicle makes", {variant: 'error'});
      }
    };

    const getDWSArr = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/DealerWebsites `, {
          cancelToken: source.token
        });

        setDwsArr(result.data.list);
        return result.data.list;
      } catch (error) {
        if (axios.isCancel(error)) return;
        // enqueueSnackbar("Unable to get vehicle makes", {variant: 'error'});
      }
    };

    getMakeArr().then(() => {
      if (!mountedRef.current) return null;
    });

    getDWSArr().then(() => {
      if (!mountedRef.current) return null;
    });

    if (!isAddMode) {
      getSlide().then(() => {
        hideLoading();
        if (!mountedRef.current) return null;
      });
    }
    return () => {
      mountedRef.current = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dealerId > 0 && selectedDealer?.motorgroupID >= 0) {
      getMotorGroupDealers();
    }
  }, [dealerId, selectedDealer]);

  useEffect(() => {
    syncRelatedDealers(motorGroupDealers, relatedDealerIDs);
  }, [motorGroupDealers, relatedDealerIDs]);

  async function getMotorGroupDealers() {
    if (!selectedDealer?.motorgroupID) {
      setIsLoadingDealers(false);
      return motorGroupDealers;
    }
  
    const params = {
      motorgroupId: selectedDealer.motorgroupID
    };
  
    try {
      const response = await axios({
        method: 'GET',
        url: `${BASE_URI}/Dealers`,
        params,
        cancelToken: axios.CancelToken.source().token
      });
  
      let dealers = response?.data?.list?.filter((d) => d.id !== dealerId);
  
      if (!dealers || dealers.length <= 0) {
        enqueueSnackbar('No motorgroup dealers available for the current dealer', { variant: 'warning' });
        setIsLoadingDealers(false);
        return;
      }
  
      setMotorGroupDealers(dealers);
      return dealers;
    } catch (error) {
      if (!axios.isCancel(error)) {
        enqueueSnackbar(getErrorMessage(error, "Unable to load the motorgroup's dealers"), { variant: 'error' });
      }
      return [];
    } finally {
      setIsLoadingDealers(false);
    }
  }
  

  function syncRelatedDealers(dealers, dealerIDs) {
    let tempRelatedDealers = [];

    for (let d = 0; d < dealers?.length; d++) {
      const dealer = dealers[d];

      if (dealer.id == dealerId) continue;

      if (dealerIDs?.some((id) => id == dealer.id)) {
        // This dealer corresponds to a related dealer, tick it
        tempRelatedDealers.push({
          dealerId: dealer.id,
          name: dealer.name.trim(),
          isTicked: true
        });
      } else {
        // This dealer does not correspond to a related dealer, untick it
        tempRelatedDealers.push({
          dealerId: dealer.id,
          name: dealer.name.trim(),
          isTicked: false
        });
      }
    }

    setRelatedDealers([...tempRelatedDealers]);
  }

  function getErrorMessage(axiosError, defaultMsg) {
    let errorTitle = defaultMsg;
    let errorDetails = 'No additional details are available';

    if (axiosError?.request?.message || axiosError?.request?.statusText) {
      errorTitle = 'Failed to send the request';
      errorDetails = axiosError?.request?.message ?? axiosError?.request?.statusText;
    } else if (axiosError?.response?.data) {
      errorTitle = axiosError?.response?.data?.title ?? errorTitle;
      errorDetails = axiosError?.response?.data?.details ?? errorDetails;
    }

    return `${errorTitle}: ${errorDetails}`;
  }

  const editSlide = async (id, fields, setSubmitting, resetForm) => {
    showLoading();
    fields.dealerId = dealerId;
    const result = await axios
      .put(`${BASE_URI}/slide/${id}`, fields)
      .then((result) => {
        enqueueSnackbar('Successfully saved the slide. Updating linked dealers', { variant: 'success' });
        saveToRelatedDealers(result?.data, setSubmitting, resetForm);
      })
      .catch((error) => {
        enqueueSnackbar(getErrorMessage(error, 'Failed to save the slide'), { variant: 'error' });
      })
      .finally(() => {
        setSubmitting(false);
        hideLoading();
      });
  };

  const createSlide = async (fields, setSubmitting, resetForm) => {
    showLoading();
    fields.dealerId = dealerId;
    await axios
      .post(`${BASE_URI}/Slide`, fields)
      .then((result) => {
        enqueueSnackbar('Successfully saved the slide. Updating linked dealers', { variant: 'success' });
        saveToRelatedDealers(result?.data, setSubmitting, resetForm);
        setSlideId(result?.data?.id);
        setIsAddMode(false);
      })
      .catch((error) => {
        enqueueSnackbar(getErrorMessage(error, 'Failed to save the slide'), { variant: 'error' });
      })
      .finally(() => {
        hideLoading();
        setSubmitting(false);
      });
  };

  async function saveToRelatedDealers(slide, setSubmitting, resetForm) {
    try {
      slide.relatedDealerIDs = relatedDealerIDs;

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      const result = await axios.post(`${BASE_URI}/Slide/UpdateRelatedDealers/${slide.id}`, slide, {
        cancelToken: source.token
      });

      enqueueSnackbar('Slide saved to linked dealers', { variant: 'success' });
      resetForm();
      history.push('/slides');

      return result;
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error, 'Failed to save the slide to the linked dealers'), { variant: 'error' });
    } finally {
      setSubmitting(false);
    }
  }

  async function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    setIsFormSubmit(true);
    const slideImage = fields.slideImageUrl;
    fields.slideImageUrl = slideImage.image || slideImage;
    fields.slideImageName = slideImage.fileName;

    const mobileImage = fields.mobileImageUrl;
    fields.mobileImageUrl = mobileImage.image || mobileImage;
    fields.mobileImageName = mobileImage.fileName;

    if (fields.isVideo === true) {
      fields.slideImageUrl = await ImageService.getBase64(fields.slideVideoFile);
    }
    setStatus();
    if (isAddMode) {
      createSlide(fields, setSubmitting, resetForm);
    } else {
      editSlide(id, fields, setSubmitting, resetForm);
    }
  }

  const handleMakeChange = async (e, value, setFieldValue) => {
    setFieldValue('make', e.target.value);
    setFieldValue('makeId', value.props.id);
  };

  function toggleRelatedDealer(dealerId) {
    // This round-about way is to ensure that the checkbox is rendered after its value is updated
    let list = [...relatedDealers];
    let dealer = list.find((d) => d.dealerId == dealerId);

    dealer.isTicked = !dealer.isTicked;

    setRelatedDealers(list);
    setRelatedDealerIDs(list.filter((d) => d.isTicked).map((d) => d.dealerId));
  }

  return (
    <Paper className={classes.paper} elevation={3} style={{ padding: '24px', borderRadius: '12px' }}>
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Grid container spacing={4} className={classes.stepPadding} direction="column">
      <Grid item xs={12}>
        <Formik
          initialValues={formFields}
          enableReinitialize={true}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            make: Yup.string().required('Make is required'),
          })}
        >
          {(props) => {
            const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;

            return (
              <Form>
                <Toolbar disableGutters>
                  <Typography variant="h5" style={{ flex: '1 1', fontWeight: 600 }} gutterBottom>
                    {isAddMode ? 'Add' : 'Edit'} Slide
                  </Typography>
                  <FormControlLabel
                    control={<Field component={Switch} color="primary" type="checkbox" name="active" />}
                    label="Active"
                  />
                  <FormControlLabel
                    control={<Field component={Switch} color="primary" type="checkbox" name="isVideo" />}
                    label="Is Video"
                  />
                </Toolbar>

                <Grid container spacing={3} direction="row">
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="name"
                      id="name"
                      required
                      label="Name"
                      value={values.name}
                      variant="outlined"
                      helperText={errors.name && touched.name ? errors.name : 'Text when you hover over the slide'}
                      error={errors.name && touched.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="url"
                      id="url"
                      required
                      label="URL"
                      value={values.url}
                      variant="outlined"
                      helperText={errors.url && touched.url ? errors.url : 'End Point'}
                      error={errors.url && touched.url}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      id="make"
                      select
                      required
                      label="Make"
                      value={values.make}
                      variant="outlined"
                      helperText={errors.make && touched.make ? errors.make : ''}
                      error={errors.make && touched.make}
                      onBlur={handleBlur('make')}
                      onChange={(e, child) => {
                        handleMakeChange(e, child, setFieldValue);
                      }}
                    >
                      {makeArr.map((option) => (
                        <MenuItem key={option.id} id={option.id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      name="category"
                      id="category"
                      label="Display Order"
                      value={values.category}
                      variant="outlined"
                      helperText={errors.category && touched.category ? errors.category : ''}
                      error={errors.category && touched.category}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      name="buttonText"
                      id="buttonText"
                      required
                      label="Button Text"
                      value={values.buttonText}
                      variant="outlined"
                      helperText={errors.buttonText && touched.buttonText ? errors.buttonText : 'Max 40 characters'}
                      error={errors.buttonText && touched.buttonText}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item container xs={12} md={6} spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={DatePicker}
                        autoOk
                        format="dd/MM/yyyy"
                        label="Start Date"
                        maxDate={values.endDate}
                        name="startDate"
                        inputVariant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        component={DatePicker}
                        autoOk
                        format="dd/MM/yyyy"
                        label="End Date"
                        minDate={values.startDate}
                        name="endDate"
                        inputVariant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="cta"
                      id="cta"
                      multiline
                      label="CTA Text"
                      value={values.cta}
                      variant="outlined"
                      helperText={errors.cta && touched.cta ? errors.cta : ''}
                      error={errors.cta && touched.cta}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  {values.isVideo ? (
                    <FileUploadInput
                      title="Slide Video"
                      required
                      fieldName={'slideVideoFile'}
                      error={errors.slideVideoFile && touched.slideVideoFile}
                    />
                  ) : (
                    <Grid container spacing={4} className={classes.stepPadding} direction="row">
                      <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                          Slide Images
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ImageUploadInput
                          title="Slide"
                          required
                          value={values.slideImageUrl}
                          fieldName={'slideImageUrl'}
                          error={errors.slideImageUrl && touched.slideImageUrl}
                        />
                        <Box mt={2}>
                        <TextField
                          fullWidth
                          name="altTag"
                          id="altTag"
                          label="Alt Tag"
                          value={values.altTag}
                          variant="outlined"
                          helperText={errors.altTag && touched.altTag ? errors.altTag : 'Alt tag for SEO'}
                          error={errors.altTag && touched.altTag}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <ImageUploadInput
                          title="Slide Mobile"
                          fieldName={'mobileImageUrl'}
                          error={errors.mobileImageUrl && touched.mobileImageUrl}
                        />
                        <Box mt={2}>
                        <TextField
                          fullWidth
                          name="mobileAltTag"
                          id="mobileAltTag"
                          label="Alt Tag"
                          value={values.mobileAltTag}
                          variant="outlined"
                          helperText={errors.mobileAltTag && touched.mobileAltTag ? errors.mobileAltTag : 'Alt tag for SEO'}
                          error={errors.mobileAltTag && touched.mobileAltTag}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" gutterBottom>
                      Select the dealers to link to this slide
                    </Typography>
                    <table width="100%" border="1" className={classes.table}>
                      <thead>
                        <tr className={classes.tr}>
                          <th className={classes.th}>Dealer Name</th>
                          <th className={classes.th}>Linked</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(isLoadingDealers || isLoading) && (
                          <tr>
                            <td colSpan={2} className={classes.tdCenter}>
                              <CircularProgress />
                            </td>
                          </tr>
                        )}
                        {!isLoadingDealers && !isLoading && !(relatedDealers?.length > 0) && (
                          <tr>
                            <td colSpan={2} className={classes.tdCenter}>
                              <Typography variant="caption">No other dealers in this motorgroup</Typography>
                            </td>
                          </tr>
                        )}
                        {!isLoadingDealers &&
                          !isLoading &&
                          relatedDealers?.length > 0 &&
                          relatedDealers.map((item, index) => (
                            <tr key={index}>
                              <td className={classes.td}>
                                <div key={`${index}-1`}>{item.name}</div>
                              </td>
                              <td className={classes.tdCenter}>
                                <div key={`${index}-2`}>
                                  <Checkbox
                                    name={item.name}
                                    color="primary"
                                    checked={item.isTicked}
                                    disabled={isSubmitting || isFormSubmit}
                                    onChange={() => {
                                      toggleRelatedDealer(item.dealerId);
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Grid>

                  <Grid item container justifyContent="flex-end" xs={12}>
                    <Box mr={3}>
                      <Button
                        onClick={() => {
                          handleReset();
                          history.push('..');
                        }}
                        variant="outlined"
                        color="secondary"
                      >
                        Cancel
                      </Button>
                    </Box>
                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting || isFormSubmit}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  </MuiPickersUtilsProvider>
</Paper>

  );
}
