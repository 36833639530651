import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Box } from '@material-ui/core'
import Button from "@material-ui/core/Button";
import { useSnackbar } from 'notistack';
import { ReportingContext } from "../../shared/contexts/ReportingContext";
import { DealerContext } from "../../shared/DealerContext";
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants'
import { CSVLink } from "react-csv";
import DatePickHelper from "../Reporting/DatePickHelper";

const useStyles = makeStyles((theme) => ({
    root: {},
    step: {
        marginTop: "2rem",
        marginLeft: "2rem",
    },
    buttonMake: {
        marginTop: "2rem",
        backgroundColor: "#0b7d95",
        color: "white"
    },
    cellText: {
        background: "#ebd852"
    },
}));

export default function ExportContainer() {
    const classes = useStyles();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [data, setData] = useState('');
    const [ranges, setRanges] = useState([]);
    let { ...context } = useContext(ReportingContext);
    const { enqueueSnackbar } = useSnackbar();
    let { dealerId } = useContext(DealerContext);

    useEffect(() => {
        setRanges([]);
    }, [context.toDate, context.fromDate]);

    const exportCSV = () => {
        setIsSubmitting(true);
        const params = {};
        params.toDate = context.toDate;
        params.fromDate = context.fromDate;
        params.dealerId = dealerId;

        axios({
            method: 'GET',
            url: `${BASE_URI}/leads/export`,
            params
        }).then((response) => {

            setData(response.data);
            setIsSubmitting(false);
        }).catch(error => {
            if (axios.isCancel(error)) return
            if (error.response) {
                //setError(true)
            }
            setIsSubmitting(false);
        })
    }

    const handleExportCSVClick = () => {
        if (!dealerId) {
          // Show snackbar if dealerId is not selected
          enqueueSnackbar('Please select a dealer first.', { variant: 'warning' });
        } else {
          // If dealerId is selected 
          exportCSV();
        }
      };

    return (
        <div className={classes.root}>
            <Grid
                container
                justify={"space-around"}
                alignItems="flex-start"
            >
                <Grid item xs={10} md={8} lg={12} className={classes.step}>
                    <DatePickHelper />
                </Grid>
                <Grid item xs={10} md={8} lg={12} className={classes.step}>
                    <Box mr={3}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={handleExportCSVClick}
                        >
                            Generate CSV
                        </Button>
                    </Box>
                    <Box mr={3}>
                        {data.length > 0 && <>
                            <CSVLink
                                style={{ textDecoration: 'none' }}
                                data={data}
                                filename={'export.csv'}
                                target="_blank"
                            >
                                Download CSV
                        </CSVLink></>
                        }
                    </Box>
                </Grid>
                <Grid item container
                    justify='flex-end' xs={12}>

                </Grid>
            </Grid>
        </div>
    );
}
