import React, { useEffect, useState, useContext } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import { Grid, TextField, Button, Divider, Paper, Fab, FormControlLabel, Checkbox } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Formik, Form, FieldArray, Field } from 'formik';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { BASE_URI } from '../../shared/Constants';
import { useSnackbar } from 'notistack';
import { DealerContext } from '../../shared/DealerContext';
import { DealerSelect } from '../../shared/DealerSelect';
import ImageUploadInput from '../../shared/ImageUploadFormInput';
import { TextField as TextFieldFormik } from 'formik-material-ui';
import { Autocomplete } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import { LoadingContext } from '../../shared/context/loadingContext';
const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  errorText: {
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    color: '#f44336'
  },
  featureInputContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  title: {
    background: '#bdbfc1',
    color: 'white',
    padding: '5px 30px',
    width: 'fit-content',
    borderBottom: '2px red solid'
  },
  configurationStyling: {
    height: '48rem',
    overflowY: 'scroll'
  },

  tr: {
    padding: '5px 30px'
  },
  td: {
    padding: '5px 10px'
  },

  btn: {
    color: '#fff',
    background: '#3f51b5',
    padding: '8px 20px',
    border: 'none',
    margin: '5px',
    borderRadius: '3px'
  }
}));

export default function WebsiteInfo({ props }) {
  const initialValues = {
    websiteUrl: '',
    xNeeloInfo: '',
    stagingInfo: '',
    repo: '',
    xNeeloFtpUser:'',
    offersUrl:'',
    usedCarsUrl:'',
    image: '',
    imageAltTag: '',
    bannerImage: '',
    bannerImageAltTag: '',
    automaticBuild: false,
    links: []
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [formFields, setFormFields] = useState(initialValues);
  const classes = useStyles();
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);

  const { selectedMotorGroup, updateSelectedMotorGroup } = useContext(DealerContext);
  const [errorMessage, setErrorMessage] = useState(false);

  const { id } = useParams();
  const [isAddMode, setIsAddMode] = useState(!id);

  const [configuration, setConfiguration] = useState('');

  let history = useHistory();

  function onSubmit(fields) {
    try {
      showLoading();
      const logo = fields.image;
      const bannerImage = fields.bannerImage;

      fields.image = logo?.image || logo;
      fields.bannerImage = bannerImage?.image || bannerImage;
      fields.configuration = configuration;

      if (!errorMessage) {
        if (formFields.id) {
          axios
            .put(
              `${BASE_URI}/DealerWebsites/${formFields.id}`,
              {
                ...fields,
                id: formFields.id,
                dealerId: selectedMotorGroup.id
              }
            )
            .then((result) => {
              history.push('/websites');
              enqueueSnackbar('Updated website info', { variant: 'success' });
              hideLoading();
            });
        } else {
          axios
            .post(
              `${BASE_URI}/DealerWebsites`,
              {
                ...fields,
                dealerId: selectedMotorGroup.id
              }
            )
            .then((result) => {
              history.push('/websites');
              enqueueSnackbar('Added new website', { variant: 'success' });
              hideLoading();
            });
        }
      } else {
        enqueueSnackbar('invalid JSON', { variant: 'error' });
        hideLoading();
      }
    } catch (error) {
      hideLoading();
      enqueueSnackbar('Operation failed', { variant: 'error' });
    }
  }

  let boundArrayHelpers;
  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  };

  useEffect(() => {
    const getWebsiteInfo = async () => {
      try {
        showLoading();
        if (id) {
          const result = await axios.get(`${BASE_URI}/DealerWebsites/Website/${id}`);
          const site = result.data;

          setConfiguration(site.configuration);
          setFormFields(site);
        }
        hideLoading();
      } catch (error) {
        enqueueSnackbar('an error occurred', { variant: 'error' });
        hideLoading();
      }
    };

    getWebsiteInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfiguration = (code) => {
    setConfiguration(code);
    try {
      // Attempt to parse the JSON
      JSON.parse(code);
      // Clear the error message if parsing is successful
      setErrorMessage(false);
    } catch (error) {
      // Set an error message if parsing fails
      setErrorMessage(true);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Formik initialValues={formFields} onSubmit={onSubmit} enableReinitialize={true}>
        {(props) => {
          const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset } = props;

          return (
            <Form>
              <Grid container spacing={4} className={classes.stepPadding} direction='row'>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Typography variant='h6' gutterBottom>
                      {isAddMode ? 'Add' : 'Edit'} info
                    </Typography>
                    <Typography variant='h7' gutterBottom>
                      General Information:
                    </Typography>
                  </Grid>
                  <TextField
                    fullWidth
                    name='websiteUrl'
                    id='websiteUrl'
                    label='Website URL'
                    value={values.websiteUrl}
                    type='text'
                    helperText={errors.websiteUrl && touched.websiteUrl ? errors.websiteUrl : 'Site Domain'}
                    error={errors.websiteUrl && touched.websiteUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />

                  <TextField
                    fullWidth
                    name='xNeeloInfo'
                    id='xNeeloInfo'
                    label='Xneelo URL'
                    value={values.xNeeloInfo}
                    type='text'
                    helperText={errors.xNeeloInfo && touched.xNeeloInfo ? errors.xNeeloInfo : 'Xneelo (Prod)'}
                    error={errors.xNeeloInfo && touched.xNeeloInfo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />

                  <TextField
                    fullWidth
                    name='stagingInfo'
                    id='stagingInfo'
                    label='Staging URL'
                    value={values.stagingInfo}
                    type='text'
                    helperText={errors.stagingInfo && touched.stagingInfo ? errors.stagingInfo : 'S3 (staging)'}
                    error={errors.stagingInfo && touched.stagingInfo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <TextField
                    fullWidth
                    name='xNeeloFtpUser'
                    id='xNeeloFtpUser'
                    label='Xneelo FTP User'
                    value={values.xNeeloFtpUser}
                    type='text'
                    helperText={errors.xNeeloFtpUser && touched.xNeeloFtpUser ? errors.xNeeloFtpUser : 'Xneelo FTP User'}
                    error={errors.xNeeloFtpUser && touched.xNeeloFtpUser}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <TextField
                    fullWidth
                    name='repo'
                    id='repo'
                    label='Repo'
                    value={values.repo}
                    type='text'
                    helperText={errors.repo && touched.repo ? errors.repo : 'repo'}
                    error={errors.repo && touched.repo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <TextField
                    fullWidth
                    name='offersUrl'
                    id='offersUrl'
                    label='Offers Url'
                    value={values.offersUrl}
                    type='text'
                    helperText={errors.offersUrl && touched.offersUrl ? errors.offersUrl : 'Offers Url'}
                    error={errors.offersUrl && touched.offersUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <TextField
                    fullWidth
                    name='usedCarsUrl'
                    id='usedCarsUrl'
                    label='Used Cars Url'
                    value={values.usedCarsUrl}
                    type='text'
                    helperText={errors.usedCarsUrl && touched.usedCarsUrl ? errors.usedCarsUrl : 'Used Cars Url'}
                    error={errors.usedCarsUrl && touched.usedCarsUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      id='automaticBuild'
                      name='automaticBuild'
                      checked={values.automaticBuild}
                      control={<Checkbox name={values.automaticBuild} color='primary' onChange={handleChange('automaticBuild')} />}
                      label={'Automatic build'}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid item xs={12}>
                    <div className={classes.configurationStyling}>
                      <Editor
                        value={configuration || '{}'}
                        padding={10}
                        onValueChange={handleConfiguration}
                        highlight={(code) => highlight(code, languages.js)}
                        style={{
                          fontFamily: 'monospace',
                          fontSize: 17
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid container spacing={4} className={classes.stepPadding} direction='row'>
                  <Grid item xs={12}>
                    <Typography variant='h6' gutterBottom>
                      Website Images
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>

                    <Grid item xs={12} md={6} style={{ maxWidth: 'none' }}>
                      <ImageUploadInput
                        title='Logo'
                        required
                        value={values.image}
                        fieldName={'image'}
                        error={errors.image && touched.image}
                      />

                      <TextField
                        fullWidth
                        name='imageAltTag'
                        id='imageAltTag'
                        label='Alt Tag'
                        value={values.imageAltTag}
                        type='text'
                        helperText={errors.imageAltTag && touched.imageAltTag ? errors.imageAltTag : 'Alt tag for SEO'}
                        error={errors.imageAltTag && touched.imageAltTag}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} md={6} style={{ maxWidth: 'none' }}>
                      <ImageUploadInput title='Banner Image' fieldName={'bannerImage'} error={errors.bannerImage && touched.bannerImage} />
                      <TextField
                        fullWidth
                        name='bannerImageAltTag'
                        id='bannerImageAltTag'
                        label='Alt Tag'
                        value={values.bannerImageAltTag}
                        type='text'
                        helperText={errors.bannerImageAltTag && touched.bannerImageAltTag ? errors.bannerImageAltTag : 'Alt tag for SEO'}
                        error={errors.bannerImageAltTag && touched.bannerImageAltTag}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    {
                      <FieldArray
                        name='links'
                        render={(arrayHelpers) => {
                          bindArrayHelpers(arrayHelpers);
                          return (
                            <Grid container spacing={2} direction='row'>
                              <Grid item xs={12}>
                                <Grid item style={{ padding: 0 }} xs={12}>
                                  <Typography variant='subtitle2'>Links:</Typography>
                                  {touched.links && errors.links && <p className={classes.errorText}>{errors.links}</p>}
                                </Grid>
                              </Grid>
                              {values.links && values.links.length > 0 ? (
                                values.links.map((link, index) => {
                                  return (
                                    <Grid item xs={12} key={index} md={4} container direction='row'>
                                      <Grid item xs={12}>
                                        <div className={classes.featureInputContainer}>
                                          <Field name={`links.${index}.name`} label='Name' component={TextFieldFormik} />
                                          <Field
                                            name={`links.${index}.link`}
                                            label='Link'
                                            style={{ marginLeft: '1rem' }}
                                            component={TextFieldFormik}
                                          />
                                          <IconButton aria-label={`Delete link`} onClick={() => arrayHelpers.remove(index)}>
                                            <DeleteIcon />
                                          </IconButton>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  );
                                })
                              ) : (
                                <Grid item xs={12}>
                                  <Typography variant='subtitle1'>No links added</Typography>
                                </Grid>
                              )}
                              <Fab
                                variant='extended'
                                size='small'
                                color='primary'
                                aria-label='add'
                                disabled={isSubmitting}
                                onClick={() =>
                                  boundArrayHelpers.insert(0, {
                                    name: '',
                                    link: ''
                                  })
                                }
                              >
                                <AddIcon />
                                Add Link
                              </Fab>
                            </Grid>
                          );
                        }}
                      />
                    }
                  </Grid>
                  <Grid item container justify='flex-end' xs={12}>
                    <Button type='submit' variant='contained' color='primary'>
                      Save
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
}
