import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Button,
  Typography
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { DealerContext } from '../../shared/DealerContext';
import { LoadingContext } from '../../shared/context/loadingContext';
import { BASE_URI } from '../../shared/Constants';
import axios from 'axios';
import { TemplateContext } from '../../shared/context/TemplateContext';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  textField: {
    marginBottom: theme.spacing(2)
  }
}));

export default function Configuration({ initialValues, onSubmit, isAddMode }) {
  const history = useHistory();
  const [oemTemplates, setOemTemplates] = useState([]);
  const { selectedOemTemplate, setSelectedOemTemplate } = useContext(TemplateContext);
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading } = useContext(LoadingContext);
  const { dealerArr, dealerId } = useContext(DealerContext);
  const classes = useStyles();

  useEffect(() => {
    const fetchOemTemplates = async () => {
      try {
        const response = await axios.get(`${BASE_URI}/WebsiteTemplates/templatesByOem`, {
          params: { oemTemplate: true }
        });
  
        let fetchedTemplates = Array.isArray(response.data) ? response.data : [];
  
        // Remove duplicates based on name and dealerId
        const uniqueTemplates = fetchedTemplates.filter((template, index, self) =>
          index === self.findIndex((t) => t.name === template.name && t.dealerId == dealerId)
        );
  
        setOemTemplates(uniqueTemplates);
      } catch (error) {
        console.error('Error fetching OEM templates:', error);
      }
    };

    fetchOemTemplates();
  }, []);

  const handleCreateUsingOemTemplate = (values) => {
    if (!selectedOemTemplate) {
      enqueueSnackbar('Please select an OEM template to create a new template.', { variant: 'warning' });
      return;
    }

    const newTemplate = { 
      ...selectedOemTemplate, 
      name: values.name,  
      id: undefined,        
      selectedOemTemplateId: selectedOemTemplate?.id || 0,
      oemTemplate: values.oemTemplate
    };

    onSubmit(newTemplate);
  };

  return (
    <Grid container spacing={4} direction="column">
      <Grid item xs={12}>
        <Formik
          initialValues={{
            ...initialValues,
            relatedDealerIDs: initialValues?.relatedDealerIDs || [],
            oemTemplate: true,  
            selectedTemplate: null,  
            selectedOemTemplateId: 0
          }}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, handleBlur, handleChange, setFieldValue, isSubmitting }) => {
            const handleDealerChange = (event, newValue) => {
              const uniqueDealers = [...new Set(newValue.map((dealer) => dealer.id))];

              if (uniqueDealers.includes(dealerId)) {
                enqueueSnackbar('This dealer cannot be selected.', { variant: 'warning' });
                const filteredDealers = newValue.filter((dealer) => dealer.id !== dealerId);
                setFieldValue(
                  'relatedDealerIDs',
                  filteredDealers.map((dealer) => dealer.id)
                );
              } else {
                setFieldValue('relatedDealerIDs', uniqueDealers);
              }
            };

            const handleTemplateChange = (event, newValue) => {
              setSelectedOemTemplate(newValue);

              const isOemTemplate = newValue === null || newValue === undefined;
              setFieldValue('oemTemplate', isOemTemplate);
              setFieldValue('selectedTemplate', newValue);
              setFieldValue('selectedOemTemplateId', newValue ? newValue.id : 0); 
            };
 
            return (
              <Form>
                <Grid container spacing={4} direction="row">
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      name="name"
                      id="name"
                      label="Template name"
                      value={values.name || ''}
                      type="text"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classes.textField}
                      variant='outlined'
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='body1' gutterBottom>
                      Select the dealers to link to this template
                    </Typography>
                    <Autocomplete
                      multiple
                      id='relatedDealers'
                      options={dealerArr.filter(d => d.id !== dealerId)}
                      getOptionLabel={(option) => option.name}
                      value={dealerArr.filter((dealer) => values.relatedDealerIDs.includes(dealer.id))}
                      onChange={handleDealerChange}
                      renderInput={(params) => (
                        <TextField {...params} variant='outlined' placeholder='Select Dealers' className={classes.textField} />
                      )}
                      disabled={isSubmitting || isLoading}
                      fullWidth
                    />
                  </Grid>

                  {isAddMode && (
                    <Grid item xs={12}>
                      <Typography variant='body1' gutterBottom>
                        Select an OEM Template (optional)
                      </Typography>
                      <Autocomplete
                        id='selectedTemplate'
                        options={oemTemplates}
                        getOptionLabel={(option) => option.name || ''}
                        value={values.selectedTemplate || null}
                        onChange={handleTemplateChange}
                        renderInput={(params) => (
                          <TextField {...params} variant='outlined' placeholder='Select OEM Template' className={classes.textField} />
                        )}
                        disabled={isSubmitting || isLoading}
                        fullWidth
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid container spacing={2} justify='flex-end'>
                  <Grid item>
                    <Button onClick={() => history.push('/templates')}>Cancel</Button>
                  </Grid>

                  <Grid item>
                    <Button
                      type='submit'
                      variant='contained'
                      color={isAddMode && !values.oemTemplate ? 'primary' : 'default'}  
                      disabled={isSubmitting || isLoading}
                      onClick={isAddMode && !values.oemTemplate ? () => handleCreateUsingOemTemplate(values) : undefined}
                    >
                      {isAddMode ? (!values.oemTemplate ? 'Create Using OEM' : 'Save') : 'Save'}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
