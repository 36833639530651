import React, { useEffect, useState, useRef, useContext } from "react";
import { Grid, TextField, Button, Box, Paper, CircularProgress } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { AUTH_USER, BASE_URI } from "../../shared/Constants";
import moment from "moment";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { AuthContext } from "../../shared/context/AuthContext";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiCardHeader-title": {
			fontSize: "20px",
		},
	},
	flexCenter: {
		display: "flex",
		width: "100%",
		justifyContent: "center",
	},
	stepPadding: {
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	errorText: {
		fontSize: "0.75rem",
		marginTop: "3px",
		textAlign: "left",
		fontWeight: 400,
		lineHeight: "1.66",
		letterSpacing: "0.03333em",
		color: "#f44336",
	},
	featureInputContainer: {
		display: "flex",
		justifyContent: "space-between",
	},
}));

export default function DealerPrivateNotes({ onSubmit, cancel, data }) {
	const initialValues = {
		message: "",
	};
	const { id } = useParams();
	const [formValues, setFormValues] = useState(data ? data : initialValues);
	const {  userSession } = useContext(AuthContext);
	const mountedRef = useRef(true);
	const classes = useStyles();
	const [notesArr, setNotesArr] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (data) {
			setFormValues(data);
		}
  }, [data]);

  const getPrivateNotes = async () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    try {
      const result = await axios.get(`${BASE_URI}/DealerNotes/${id}`, {
        cancelToken: source.token,
      })

      return result;
    } catch (error) {
      return [];
      // addAlert('Unables to load vehicle categories')
      //history.push('..')
    }
  };

	useEffect(() => {
		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		getPrivateNotes().then((result) => {
      setNotesArr(result.data)
			if (!mountedRef.current) return null;
    });

		return () => {
			mountedRef.current = false;
			source.cancel();
		};
  }, []);

  const createNote = async (fields, setSubmitting, resetForm) => {

    resetForm();

    let authenticatedUser = userSession;
    console.log("AUTH" , authenticatedUser)
    let body = {
      note: fields.message,
      dealerId: parseInt(id),
      authUserEmail: authenticatedUser.email
    }

    const CancelToken = axios.CancelToken;
			const source = CancelToken.source();
			const result = await axios.post(`${BASE_URI}/DealerNotes`, body, {
				cancelToken: source.token,
      });

      setSubmitting(false);
      getPrivateNotes().then((result) => {
        setNotesArr(result.data)
      });
  }

	return (
		<Paper className={classes.paper}>
			<Grid container spacing={4} className={classes.stepPadding} direction="column">
				<Grid item xs={12}>
					<Formik
						initialValues={formValues}
						onSubmit={(values, actions) => {
              console.log("ACTIONS", actions)

              createNote(values, actions.setSubmitting, actions.resetForm);
						}}
						validationSchema={Yup.object().shape({
							// make: Yup.string().required('Select a Make for the vehicle'),
							// model: Yup.string().required('Select a Model for the vehicle'),
							// title: Yup.string().required('Enter a title for the Range option'),
							// image: Yup.string().required('Vehicle image is required')
						})}>
						{(props) => {
							const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;
							return (
								<Form>
									<Typography variant="h6" gutterBottom>
										Private Notes
									</Typography>
									<Grid spacing={4} container style={{ marginTop: "1rem" }} justify="space-around" direction="row">
										<Grid item xs={12} md={6}>
											<TextField
												fullWidth
												name="message"
												id="message"
												variant="outlined"
												multiline
												minRows={9}
												label="Note"
												value={values.message}
												helperText={errors.message && touched.message ? errors.message : ""}
												error={errors.message && touched.message}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
											<Grid item container justify="flex-end" xs={12}>
												<Box mr={3}>
													<Button
														onClick={() => {
															handleReset();
														}}>
														Clear
													</Button>
												</Box>
												<Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
													Submit
												</Button>
											</Grid>
										</Grid>

										<Grid item xs={12} md={6}>
											{notesArr.length > 0 &&
												notesArr.map((note) => {
													return (
														<Card elevation={2} style={{ marginBottom: "1rem" }} key={note.id}>
															<CardHeader
																className={classes.root}
																title={note.name}
																action={
																	<Typography color="textSecondary" variant="subtitle1">
																		{moment(note.date).format('l')}
																	</Typography>
																}
															/>
															<CardContent>
																<Typography variant="body2">{note.message}</Typography>
															</CardContent>
														</Card>
													);
												})}
											{(!notesArr.length || notesArr.length === 0) && !loading && (
												<Typography color="textSecondary" variant="subtitle1">
													No Private notes
												</Typography>
											)}
											{loading && (
												<div className={classes.flexCenter}>
													<CircularProgress />
												</div>
											)}
										</Grid>
									</Grid>
								</Form>
							);
						}}
					</Formik>
				</Grid>
			</Grid>
		</Paper>
	);
}
