import React, { useState, createContext, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { BASE_URI, ROLE_ADMIN } from './Constants'
import { AuthContext } from './context/AuthContext'

export const DealerContext = createContext();

export const DealerContextProvider = (props) => {
    const [selectedMotorGroup, setSelectedMotorGroup] = useState({})
    const [motorGroupId, setMotorGroupId] = useState('')
    const [isMasterDealer, setIsMasterDealer] = useState(false);
    const [dealerId, setDealerId] = useState('')
    const [dealerName, setDealerName] = useState('')
    const mountedRef = useRef(true)
    const { userDealerIDs, role } = useContext(AuthContext);
    const [dealerArr, setDealerArr] = useState([]);

    const hasRole = (roleToCheck) => {
        if (!role) {
            return false;
        }

        return role === roleToCheck;
    }

    const filterDealersForUser = (dealers) => {

        if (!dealers || dealers.length === 0 || hasRole(ROLE_ADMIN))
            return;

        var dealerArr = dealers.filter(d => userDealerIDs.includes(d.id));
        setDealerArr(dealerArr)
    }

    useEffect(() => {
        const getDealers = async () => {
            try {
                const result = await axios.get(`${BASE_URI}/dealers`, {
                    params:{pageNumber:-1}
                })
                 setDealerArr(result?.data.list)
            } catch (error) {
                if (axios.isCancel(error)) return
                //enqueueSnackbar("Unable to load dealerships", {variant: 'error'});
            }
        }

        getDealers().then((dealers) => {
            var thisDealer = findDealer((userDealerIDs && userDealerIDs.length > 0 ? userDealerIDs[0] : 0), dealers)
            //filterDealerOnMotorGroup(thisDealer, dealers)
            filterDealersForUser(dealers);
            if (!mountedRef.current) return null
        })
        return () => {
            mountedRef.current = false
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDealerIDs])

    const updateSelectedMotorGroup = (motorGroup, dealerId) => {
        setSelectedMotorGroup(motorGroup)
        setDealerId(dealerId)
        setIsMasterDealer(getIsMasterDealer(motorGroup));
    }

    const findDealer = (id, dealers) => {
        if (!id || !Array.isArray(dealers) || dealers.length === 0) return;

        const dealer = dealers.find((d) => d.id === id);

        if(dealer){
            setSelectedMotorGroup(dealer);
            setDealerId(dealer?.id);
            setDealerName(dealer?.name);
        }

        return dealer;
      };

    const updateMotorGroupId = (id) => {
        setMotorGroupId(id)
    }

    const getIsMasterDealer = (dealer = selectedMotorGroup) => {
        // A dealer is a master when its name contains the word 'master' or when its name is the same as the motorgroup's name
        return (dealer?.name?.toLowerCase().includes("master"));// || dealer?.name?.trim().toLowerCase() == dealer?.motorGroupName?.trim().toLowerCase());
    };

    return (
        <DealerContext.Provider value={{
            dealerArr,
            setDealerArr,
            selectedMotorGroup,
            motorGroupId,
            dealerId,
            dealerName,
            isMasterDealer,
            updateMotorGroupId,
            updateSelectedMotorGroup
        }}>
            {props.children}
        </DealerContext.Provider>
    );
}

export default DealerContextProvider;
