import React, { useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Grid,
  Button,
  Box,
  Switch,
  FormControlLabel,
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextField as TextFieldFormik } from 'formik-material-ui';
import { BASE_URI } from '../../../shared/Constants';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { LoadingContext } from '../../../shared/context/loadingContext';
import { DealerContext } from '../../../shared/DealerContext';
import { useHistory, useParams } from 'react-router-dom';

const initialValues = {
  toolbar: false,
  header: false,
  footer: false,
  toolbarText: '',
  headerText: '',
  footerText: '',
  toolbarOrder: 0,
  headerOrder: 0,
  footerOrder: 0,
  htmlModule1: false,
  htmlModule2: false,
  htmlModule1Text: '',
  htmlModule2Text: '',
  htmlModule1Order: 0,
  htmlModule2Order: 0
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  dynamicComponentCell: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const BasePage = (props) => {
  const { page } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedMotorGroup } = useContext(DealerContext);
  const parsedObj = page?.templateConfig ? JSON.parse(page.templateConfig) : initialValues;
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { id } = useParams();
  const history = useHistory();

  const onSubmitUpdatePage = async (fields) => {
    showLoading();
    try {
      fields.bobPayload = transformToBobPayload(fields);
      if (selectedMotorGroup.id) {
        if (page) {
          const pageId = page.id;
          page.templateConfig = JSON.stringify(fields);
          page.url = fields.url;
          page.title = fields.title;
          page.description = fields.description;
          page.content = fields.content;

          await axios.put(`${BASE_URI}/WebsitePages/${pageId}`, {
            ...page,
            id: pageId
          });

          enqueueSnackbar('Updated basepage page', { variant: 'success' });
        } else {
          const newPage = {
            url: fields.url,
            title: fields.title,
            description: fields.description,
            content: '',
            templateConfig: JSON.stringify(fields),
            dealerID: selectedMotorGroup.id,
            websiteTemplatesId: parseInt(id),
            pageCategoryId: 0
          };

          await axios.post(`${BASE_URI}/WebsitePages`, newPage);

          enqueueSnackbar('Created base page', { variant: 'success' });
          history.push('/templates');
        }
      } else {
        enqueueSnackbar('Select a dealer first', { variant: 'warning' });
      }
    } catch (error) {
      enqueueSnackbar('Operation failed', { variant: 'error' });
    } finally {
      hideLoading();
    }
  };

  const transformToBobPayload = (values) => {
    const layoutMap = new Map();

    const addToLayoutMap = (partial, order, includes, params = []) => {
      if (includes || params.length > 0) {
        if (!layoutMap.has(partial)) {
          layoutMap.set(partial, { partial, order, includes, type: 'default', params });
        } else {
          const existing = layoutMap.get(partial);
          existing.includes = existing.includes || includes;
          existing.params.push(...params);
        }
      }
    };

    Object.keys(values).forEach((field) => {
      if (['includePage', 'title', 'description', 'url', 'bobPayload'].includes(field)) {
        return;
      }

      const includes = values[field] ? true : false;
      const order = values[`${field}Order`] || 0;
      let params = [];

      if (field !== 'image' && !field.endsWith('Text') && !field.endsWith('Order')) {
        if (values[`${field}Text`] !== undefined) {
          params.push({ key: `${field}Text`, value: values[`${field}Text`] });
        }
        addToLayoutMap(field, order, includes, params);
      }

      if (field === 'image' && values[field]) {
        params = [{ key: 'image', value: values[field] }];
        addToLayoutMap(field, 0, false, params);
      }
    });

    const layout = Array.from(layoutMap.values());

    return {
      layout,
      page: page?.title || '',
      image: values.image || {}
    };
  };


  return (
    <Paper className={classes.paper}>
      <Toolbar>
        <Typography variant='h6' component='div'>
          Base
        </Typography>
      </Toolbar>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Formik
            initialValues={parsedObj}
            onSubmit={(values) => {
              onSubmitUpdatePage(values);
            }}
          >
            {({ values, setFieldValue }) => {
              const handleChangeCustom = (fieldName, checked) => {
                setFieldValue(fieldName, checked);
              };

              return (
                <Form>
                  <TableContainer className={classes.tableContainer}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Module</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Include</TableCell>
                          <TableCell>Content</TableCell>
                          <TableCell>Order</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>toolbar</TableCell>
                          <TableCell>Section</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.toolbar}
                                  onChange={(e) => handleChangeCustom('toolbar', e.target.checked)}
                                  name='toolbar'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.toolbar && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='toolbarText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field name='toolbarOrder' type='number' component={TextFieldFormik} label='Order' fullWidth />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>header</TableCell>
                          <TableCell>Section</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.header}
                                  onChange={(e) => handleChangeCustom('header', e.target.checked)}
                                  name='header'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.header && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='headerText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='headerOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>footer</TableCell>
                          <TableCell>Section</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.footer}
                                  onChange={(e) => handleChangeCustom('footer', e.target.checked)}
                                  name='footer'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.footer && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='footerText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='footerOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>htmlModule1</TableCell>
                          <TableCell>Module</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.htmlModule1}
                                  onChange={(e) => handleChangeCustom('htmlModule1', e.target.checked)}
                                  name='htmlModule1'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.htmlModule1 && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='htmlModule1Text'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='htmlModule1Order'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>htmlModule2</TableCell>
                          <TableCell>Module</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.htmlModule2}
                                  onChange={(e) => handleChangeCustom('htmlModule2', e.target.checked)}
                                  name='htmlModule2'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.htmlModule2 && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='htmlModule2Text'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='htmlModule2Order'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={2}>
                    <Button type='submit' color='primary' variant='contained'>
                      Save
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BasePage;
