import React, { useEffect, useState, useContext, useRef } from 'react';
import { Grid, TextField, Button, Paper, Divider, Toolbar,Box,MenuItem  } from '@material-ui/core';
import { LoadingContext } from '../../shared/context/loadingContext';
import { LeadTypeEnum } from '../../shared/Constants';
import Typography from '@material-ui/core/Typography';
import { Formik, FieldArray, Form } from 'formik';
import { BASE_URI } from '../../shared/Constants';
import { useHistory, useParams } from 'react-router-dom';
import { DealerContext } from '../../shared/DealerContext';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';
import * as Yup from 'yup';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  textField: {
    marginBottom: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  dividerSpacing: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  dynamicFieldsContainer: {
    marginBottom: theme.spacing(4),
    maxHeight: '400px',
    overflowY: 'auto'
  }
}));

export default function TemplateFormAddEdit() {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [isAddMode] = useState(!id);
  const { enqueueSnackbar } = useSnackbar();
  const { showLoading, hideLoading, isLoading } = useContext(LoadingContext);
  const [leadTypes, setLeadTypes] = useState([]);
  const { dealerArr, dealerId } = useContext(DealerContext);
  const mountedRef = useRef(true);

  const [formFields, setFormFields] = useState({
    name: '',
    url: '',
    dynamicFields: [{ labelName: '', keyName: '' }],
    relatedDealerIDs: [],
    pageCategoryId: null,
    messageTemplateId: null,
    leadTypeId: null
  });

  const [keyNames, setKeyNames] = useState([]);
  const [pageCategory, setPageCategory] = useState([]);
  const [linkedTemplates, setLinkedTemplates] = useState([]);

  useEffect(() => {
    const getTemplateForm = async () => {
      if (isAddMode) return;

      try {
        showLoading();
        const result = await axios.get(`${BASE_URI}/TemplateForm/${id}`);
        const data = result.data;
        const parsedDynamicFields = JSON.parse(data.inputMap || '[]');

        if (mountedRef.current) {
          setFormFields({
            name: data.name || '',
            url: data.url || '',
            dynamicFields: parsedDynamicFields,
            relatedDealerIDs: data.relatedDealerIDs || [],
            pageCategoryId: data.pageCategoryId,
            messageTemplateId: data.messageTemplateId || null,
            leadTypeId: data.leadTypeId || null
          });
        }
      } catch (error) {
        console.error('Error fetching template form:', error);
        enqueueSnackbar('Unable to get template form details', { variant: 'error' });
      } finally {
        if (mountedRef.current) {
          hideLoading();
        }
      }
    };

    const fetchKeyNames = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/TemplateForm/formTemplateKeys`);
        setKeyNames(result.data);
      } catch (error) {
        enqueueSnackbar('Unable to fetch key names', { variant: 'error' });
      }
    };

    function getLeadTypes() {
        const allLeadTypes = [...Object.entries(LeadTypeEnum).map(([key, value]) => ({ id: value, name: key }))];
        setLeadTypes(allLeadTypes);
    }

    const fetchPageCategory = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/WebsiteTemplates/pageCategory`);
        setPageCategory(result.data);
      } catch (error) {
        enqueueSnackbar('Unable to fetch page categories', { variant: 'error' });
      }
    };

    const fetchLinkedTemplates = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/MessageTemplate/formLinkedTemplates`);
        setLinkedTemplates(result.data);
      } catch (error) {
        enqueueSnackbar('Unable to fetch linked templates', { variant: 'error' });
      }
    };

    getTemplateForm();
    fetchKeyNames();
    fetchPageCategory();
    fetchLinkedTemplates();
    getLeadTypes();

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const onSubmit = async (fields, { setSubmitting }) => {
    const cleanedFields = {
      ...fields,
      inputMap: JSON.stringify(fields.dynamicFields),
      dealerId
    };

    const { dynamicFields, ...fieldsWithoutDynamicFields } = cleanedFields;

    try {
      showLoading();

      if (isAddMode) {
        let response = await axios.post(`${BASE_URI}/TemplateForm`, cleanedFields);
        await axios.post(`${BASE_URI}/TemplateForm/UpdateRelatedDealers/${response.data?.id}`, {
          ...fieldsWithoutDynamicFields
        });
        enqueueSnackbar('Template form created successfully!', { variant: 'success' });
      } else {
        cleanedFields.id = id;
        await axios.put(`${BASE_URI}/TemplateForm/${id}`, cleanedFields);
        await axios.post(`${BASE_URI}/TemplateForm/UpdateRelatedDealers/${id}`, {
          ...fieldsWithoutDynamicFields
        });
        enqueueSnackbar('Template form updated successfully!', { variant: 'success' });
      }

      enqueueSnackbar('Related dealers updated successfully!', { variant: 'success' });
      history.push('/templateForms');
    } catch (error) {
      console.error('Error submitting form:', error);
      const errorMsg = error?.response?.data || 'An error occurred';
      enqueueSnackbar(errorMsg, { variant: 'error' });
    } finally {
      hideLoading();
      setSubmitting(false);
    }
  };

  const handleDealerChange = (event, newValue, setFieldValue) => {
    const uniqueDealers = [...new Set(newValue.map((dealer) => dealer.id))];

    if (uniqueDealers.includes(dealerId)) {
      enqueueSnackbar('This dealer cannot be selected.', { variant: 'warning' });
      const filteredDealers = newValue.filter((dealer) => dealer.id !== dealerId);
      setFieldValue(
        'relatedDealerIDs',
        filteredDealers.map((dealer) => dealer.id)
      );
    } else {
      setFieldValue('relatedDealerIDs', uniqueDealers);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={4} className={classes.stepPadding} direction='column'>
        <Grid item xs={12}>
          <Formik
            initialValues={{
              ...formFields,
              relatedDealerIDs: formFields?.relatedDealerIDs || [],
              linkedTemplateId: formFields?.linkedTemplateId || null
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Name is required'),
              url: Yup.string().required('URL is required'),
              leadTypeId: Yup.string()
                .nullable()   
                .required('Lead Type is required'),  
              messageTemplateId: Yup.string()
                .nullable()   
                .required('Linked Email Template is required'),  
              dynamicFields: Yup.array()
                .of(
                  Yup.object().shape({
                    labelName: Yup.string().required('Label Name is required'),
                    keyName: Yup.string().required('Key Name is required'),
                  })
                )
                .min(1, 'At least one dynamic field is required'),
            })}
            
            
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleBlur, errors, touched, isSubmitting, setFieldValue }) => {
              return (
                <Form noValidate>
                  <Toolbar disableGutters>
                    <Typography variant='h6'>{isAddMode ? 'Add' : 'Edit'} Template Form</Typography>
                  </Toolbar>

                  <Grid container spacing={3}>
                    {/* Fields Section */}
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        name='name'
                        label='Name'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        className={classes.textField}
                      />
                      <TextField
                        fullWidth
                        name='url'
                        label='URL'
                        value={values.url}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.url && !!errors.url}
                        helperText={touched.url && errors.url}
                        className={classes.textField}
                      />

                      <TextField
                        select
                        fullWidth
                        name='pageCategoryId'
                        label='Page Category'
                        value={values.pageCategoryId || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.pageCategoryId && !!errors.pageCategoryId}
                        helperText={touched.pageCategoryId && errors.pageCategoryId}
                        InputLabelProps={{
                          shrink: true
                        }}
                        className={classes.textField}
                      >
                        {pageCategory.map((category) => (
                          <MenuItem key={category.id} value={category.id}>
                            {category.pageType}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        select
                        fullWidth
                        name='messageTemplateId'
                        label='Linked Email Template'
                        disabled={!isAddMode}
                        value={values.messageTemplateId || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.messageTemplateId && !!errors.messageTemplateId}
                        helperText={touched.messageTemplateId && errors.messageTemplateId}
                        InputLabelProps={{
                          shrink: true
                        }}
                        className={classes.textField}
                      >
                        {linkedTemplates.map((template) => (
                          <MenuItem key={template.id} value={template.id}>
                            {template.name}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        select
                        fullWidth
                        name='leadTypeId'
                        label='Lead Type'
                        value={values.leadTypeId || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.leadTypeId && !!errors.leadTypeId}
                        helperText={touched.leadTypeId && errors.leadTypeId}
                        disabled={!isAddMode}
                        InputLabelProps={{
                          shrink: true
                        }}
                        className={classes.textField}
                      >
                        {leadTypes.map((leadType) => (
                          <MenuItem key={leadType.id} value={leadType.id}>
                            {leadType.name}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Typography variant='body1' gutterBottom>
                        Select the dealers to link to this template form
                      </Typography>
                      <Autocomplete
                        multiple
                        id='relatedDealers'
                        options={dealerArr}
                        getOptionLabel={(option) => option.name}
                        value={dealerArr.filter((dealer) => values.relatedDealerIDs.includes(dealer.id))}
                        onChange={(event, newValue) => handleDealerChange(event, newValue, setFieldValue)}
                        renderInput={(params) => (
                          <TextField {...params} variant='outlined' placeholder='Select Dealers' className={classes.textField} />
                        )}
                        disabled={isSubmitting}
                        fullWidth
                      />
                    </Grid>

                    {/* Dynamic Fields Section */}
                    <Grid item md={6} xs={12}>
                      <Box className={classes.dynamicFieldsContainer}>
                        <Typography variant='h6'>Dynamic Fields</Typography>
                        <FieldArray name='dynamicFields'>
                          {({ push, remove }) => (
                            <div>
                              {values.dynamicFields.map((field, index) => (
                                <div key={index}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        name={`dynamicFields[${index}].labelName`}
                                        label='Label Name'
                                        value={field.labelName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.dynamicFields?.[index]?.labelName && !!errors.dynamicFields?.[index]?.labelName}
                                        helperText={touched.dynamicFields?.[index]?.labelName && errors.dynamicFields?.[index]?.labelName}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        select
                                        name={`dynamicFields[${index}].keyName`}
                                        label='Key Name'
                                        value={field.keyName}
                                        onChange={(e) => setFieldValue(`dynamicFields[${index}].keyName`, e.target.value)}
                                        onBlur={handleBlur}
                                        error={touched.dynamicFields?.[index]?.keyName && !!errors.dynamicFields?.[index]?.keyName}
                                        helperText={touched.dynamicFields?.[index]?.keyName && errors.dynamicFields?.[index]?.keyName}
                                      >
                                        {keyNames.map((option) => (
                                          <MenuItem key={option.id} value={option.name}>
                                            {option.name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <div className={classes.buttonContainer}>
                                        <Button
                                          color='secondary'
                                          variant='outlined'
                                          onClick={() => remove(index)}
                                          disabled={values.dynamicFields.length === 1}
                                        >
                                          Remove
                                        </Button>
                                        {index === values.dynamicFields.length - 1 && (
                                          <Button color='primary' variant='contained' onClick={() => push({ labelName: '', keyName: '' })}>
                                            Add
                                          </Button>
                                        )}
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Divider className={classes.dividerSpacing} />
                                </div>
                              ))}
                            </div>
                          )}
                        </FieldArray>
                      </Box>
                    </Grid>
                  </Grid>

                  <Divider className={classes.dividerSpacing} />

                  <div className={classes.buttonContainer}>
                    <Button color='secondary' variant='outlined' onClick={() => history.goBack()}>
                      Cancel
                    </Button>
                    <Button color='primary' variant='contained' type='submit' disabled={isSubmitting}>
                      Submit
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
}
