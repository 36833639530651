import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { CircularProgress } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { BASE_URI, DEFAULT_ERROR_MESSAGE } from '../../shared/Constants';
import { AuthContext } from '../../shared/context/AuthContext';
import { useSnackbar } from 'notistack';
import NewLoginDesktop from '../../shared/assets/newLogin_desktop.jpg';
import NewLoginTablet from '../../shared/assets/newLogin_tablet.jpg';
import NewLoginMobile from '../../shared/assets/newLogin_mobile.jpg';
import config from '../../config';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://reachengagedevelop.com/'>
        Reach Engage Develop
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
  background: {
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    // Media queries for responsive background images
    [theme.breakpoints.down('xs')]: {
      // For extra small devices (480px and below)
      backgroundImage: `url(${NewLoginMobile})` // Mobile image
    },
    [theme.breakpoints.between('sm', 'md')]: {
      // For small to medium devices (481px to 1024px)

      backgroundImage: `url(${NewLoginTablet})` // Tablet image
    },
    [theme.breakpoints.up('md', 'lg')]: {
      // For large devices (above 1024px)
      backgroundImage: `url(${NewLoginDesktop})` // Desktop image
    }
  },

  textGroup: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    left: '50%',
    transform: 'translateX(-50%)',

    [theme.breakpoints.down('xs')]: {
      // For extra small devices (480px and below)
      top: '38%'
    },

    [theme.breakpoints.between('sm')]: {
      // For small to medium devices (481px to 1024px)
      top: '30%'
    },

    [theme.breakpoints.between('sm', 'md')]: {
      // For small to medium devices (481px to 1024px)
      top: '33%'
    },

    [theme.breakpoints.between('md', 'lg')]: {
      // For small to medium devices (481px to 1024px)
      top: '30%'
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      // For small to medium devices (481px to 1024px)
      top: '30%'
    }
  },
  mainText: {
    color: '#1E1E1E',
    fontFamily: '"SF Pro Text", sans-serif',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
      fontSize: '28px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    }
  },
  subText: {
    color: '#1E1E1E',
    fontFamily: '"SF Pro Text", sans-serif',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 200,
    lineHeight: 'normal',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px'
    }
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4)
    }
  },
  loginBtn: {
    backgroundColor: '#03A7A1',
    color: '#fff',
    padding: theme.spacing(1.25, 3),
    borderRadius: 30,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#028e82'
    },
    width: '100%',
    maxWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '250px'
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: '8px',
    maxWidth: '400px',
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      maxWidth: '350px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
      padding: theme.spacing(1.5)
    }
  },
  form: {
    width: '100%'
  },
  link: {
    textAlign: 'start',
    color: '#3f51b5 !important',
    '&:hover': {
      textDecoration: 'underline !important'
    }
  }
}));

const LoginComponent = () => {
  const classes = useStyles();
  const { login, setSession } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLogin, setIsLogin] = useState(true);
  const theme = useTheme(); // Access the theme
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md')); // Tablet or smaller
  const [loading, setLoading] = useState(false);

  const validationSchema = isLogin ?  Yup.object().shape({
    username: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required') 

   
  }) :  Yup.object().shape({
    username: Yup.string().email('Invalid email address').required('Email is required')});
  

  const loginUser = (values) => {
    setLoading(true);
    login(values.username, values.password)
      .then((response) => {
        setLoading(false);
        let res = response.data;
        if (!res.isAuthenticated || !res.token) {
          enqueueSnackbar(res.message, { variant: 'error' });
          return;
        }

        let dealerIDs = res.dealers.map((d) => d.dealerId);
        setSession(
          values.username,
          response.data.token,
          response.data.refreshToken,
          dealerIDs,
          response.data.permissions,
          response.data.name,
          response.data.isAuthenticated
        );
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar(DEFAULT_ERROR_MESSAGE, { variant: 'error' });
      });
  };

  const resetPasswordRequest = (values) => {
    setLoading(true);
    axios
      .get(`${BASE_URI}/User/ResetPassword/${values.username}`)
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Password request sent, please check email.', { variant: 'success' });
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.data) {
          let errorMsg = (error.response.data.title ? `${error.response.data.title}. ` : '') + (error.response.data.detail ?? error);

          enqueueSnackbar(errorMsg, { variant: 'error' });
        } else {
          enqueueSnackbar(DEFAULT_ERROR_MESSAGE, { variant: 'error' });
        }
      });
  };

  return (
    <div className={classes.background}>
      <div className={classes.textGroup}>
        <Typography className={classes.mainText}>
          {isTabletOrMobile ? (
            <>
              Simple. Powerful.
              <br />
              Integrated. Fast.
            </>
          ) : (
            'Simple. Powerful. Integrated. Fast.'
          )}
        </Typography>
        <Typography className={classes.subText}>It just works!</Typography>

        <div className={classes.paper}>
          {/* Login Form */}
          {isLogin && (
            <Formik
              initialValues={{ username: '', password: '' }}
              validationSchema={validationSchema}  
              onSubmit={loginUser}  
            >
              {({ values, touched, errors, handleBlur, handleChange }) => (
                <Form className={classes.form}>
                  <TextField
                    fullWidth
                    id='username'
                    name='username'
                    label='Email Address'
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.username && errors.username}
                    error={Boolean(errors.username && touched.username)}
                  />

                  <TextField
                    fullWidth
                    type='password'
                    id='password'
                    name='password'
                    label='Password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.password && errors.password}
                    error={Boolean(errors.password && touched.password)}
                  />

                  <Link
                    className={classes.link}
                    onClick={() => {
                      setIsLogin(false);  
                    }}
                    variant='body2'
                  >
                    Forgot Password?
                  </Link>

                  <div className={classes.buttonGroup}>
                    <Button fullWidth className={classes.loginBtn} type='submit' variant='contained' color='primary' disabled={loading}>
                      {loading ? <CircularProgress size={24} /> : 'Login'}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}

          {/* Reset Password Form */}
          {!isLogin && (
            <Formik
              initialValues={{ username: '' }}  
              validationSchema={validationSchema}  
              onSubmit={resetPasswordRequest} 
            >
              {({ values, touched, errors, handleBlur, handleChange }) => (
                <Form className={classes.form}>
                  <TextField
                    fullWidth
                    id='username'
                    name='username'
                    label='Email Address'
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.username && errors.username}
                    error={Boolean(errors.username && touched.username)}
                  />

      
                  <Link
                    className={classes.link}
                    onClick={() => {
                      setIsLogin(true);  
                    }}
                    variant='body2'
                  >
                    Back to Login
                  </Link>
                  <div className={classes.buttonGroup}>
                    <Button fullWidth className={classes.loginBtn} type='submit' variant='contained' color='primary' disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Reset Password'}
                    </Button>
                  </div>

                </Form>
              )}
            </Formik>
          )}

          {/* Footer */}
          <Box mt={2}>
            <Copyright />
            <p style={{ textAlign: 'center' }}>Version: v{config.buildNumber}</p>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
