import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TableHeadersSort from '../../shared/TableHeadersSort';
import { DEFAULT_PAGE_SIZE } from '../../shared/Constants';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import MotorgroupFilter from './MotorgroupFilter';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  green: {
    color: '#8bc34a'
  },
  hoverTableCell: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  removemotorgroupbtn: {
    background: '#3f51b5',
    color: 'white',
    whiteSpace: 'nowrap',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function MotorgroupTable() {
  const classes = useStyles();
  let history = useHistory();
  const [filteredMotorgroups, setFilteredMotorgroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('motorgroupname');
  const [searchString, setSearchString] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [showDialog, setShowDialog] = useState({ show: false, activeDealer: null });
  const { enqueueSnackbar } = useSnackbar();

  const { loading, motorgroups, total } = MotorgroupFilter(searchString, page, pageSize, order, orderBy);

  const [headCells, setHeadCells] = useState([
    { id: 'name', label: 'Name', sort: true },
    { id: 'phoneNumber', label: 'Phone', sort: true },
    { id: 'email', label: 'Email', sort: true }
  ]);

  useEffect(() => {
    if (loading) {
      return;
    }
    setFilteredMotorgroups(motorgroups || []);

    // Set isLoading to false after processing.
    setIsLoading(false);
  }, [motorgroups, loading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    setPage(0); // Set the page to 0 when Enter is pressed
    setSearchString(searchInput);
  };

  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let filterSearchMotorGroup = filteredMotorgroups.filter(
    (a) => searchString === '' || a.name.toLowerCase().includes(searchString.toLowerCase())
  );

  const handleButtonClick = (id) => {
    history.push(`/motorgroup/edit/${id}`);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4} className={classes.stepPadding} direction='column'>
          <Grid item xs={12}>
            <Toolbar disableGutters style={{ marginBottom: '2rem' }}>
              <Typography style={{ flex: '1 1' }} variant='h4' id='tableTitle' component='div'>
                Motorgroups
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  name='motorgroupFilter'
                  id='motorgroupFilter'
                  label='Search Motorgroups'
                  value={searchInput}
                  type='text'
                  InputProps={{
                    endAdornment: searchInput ? (
                      <InputAdornment position='end'>
                        <IconButton size='small' disableFocusRipple disableRipple onClick={() => setSearchInput('')}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      ''
                    )
                  }}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </form>
              <Button
                onClick={() => history.push('/motorgroup/add')}
                variant='contained'
                style={{ marginLeft: '2rem' }}
                startIcon={<AddIcon />}
                color='primary'
              >
                Motorgroup
              </Button>
            </Toolbar>

            <TableContainer>
              <Table aria-label='users table' size='small'>
                <TableHeadersSort order={order} orderBy={orderBy} headCells={headCells} onRequestSort={handleRequestSort} />
                <TableBody>
                  {filterSearchMotorGroup.map((row) => (
                    <Tooltip title={`Edit ${row.name}`} key={row.id} placement='left'>
                      <TableRow hover className={classes.hoverTableCell}>
                        <TableCell onClick={() => handleButtonClick(row.id)}>
                          <Typography style={{ color: row.status === 'inActive' ? '#ff5722' : '' }} variant='subtitle2'>
                            {row.name}
                          </Typography>
                        </TableCell>
                        <TableCell onClick={() => handleButtonClick(row.id)}>
                          {row.phoneNumber}
                        </TableCell>
                        <TableCell onClick={() => handleButtonClick(row.id)}>
                          {row.email}
                        </TableCell>
                      </TableRow>
                    </Tooltip>
                  ))}
                  {!filteredMotorgroups.length && !isLoading && (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align='center' style={{ borderBottom: 0 }} className='py-3'>
                        <Typography variant='h6' color='textSecondary'>
                          No Motorgroups Added
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {isLoading && (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align='center' style={{ borderBottom: 0 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                count={total}
                page={page}
                rowsPerPage={pageSize}
                component='div'
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePageSize}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
