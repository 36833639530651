import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TableHeadersSort from '../../shared/TableHeadersSort';
import { DEFAULT_PAGE_SIZE } from '../../shared/Constants';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { DealerContext } from '../../shared/DealerContext';
import CategoryFilter from './CategoryFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  hoverTableCell: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  removecategorybtn: {
    background: '#3f51b5',
    color: 'white',
    whiteSpace: 'nowrap',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  select: {
    marginRight: theme.spacing(2),
  },
}));

export default function CategoryTable() {
  const classes = useStyles();
  let history = useHistory();

  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('name');
  const [searchString, setSearchString] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const { dealerId } = useContext(DealerContext);
  const { loading, categories, total } = CategoryFilter(searchString, page, pageSize, order, orderBy);

  const [headCells, setHeadCells] = useState([
    { id: 'name', label: 'Name', sort: true },
    { id: 'type', label: 'Type', sort: true },
    { id: 'description', label: 'Description', sort: true },
  ]);

  useEffect(() => {
    if (!loading) {
      setFilteredCategories(categories || []);
      setIsLoading(false);
    }
  }, [categories, loading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setPage(0);
    setSearchString(searchInput);
  };

  const handleFilterChange = (event) => {
    setFilterBy(event.target.value);
    setPage(0); 
  };

  // Apply the search string and filter type
  const filterSearchCategory = filteredCategories
  .filter(category =>
    // Match the category name with the search string (case-insensitive)
    searchString === '' || category.name.toLowerCase().includes(searchString.toLowerCase().trim())
  )
  .filter(category =>
    // Match the category type with the filter (case-insensitive, trimmed)
    filterBy === '' || category.type.toLowerCase().trim() === filterBy.toLowerCase().trim()
  );

  const handleButtonClick = (id) => {
    history.push(`/category/edit/${id}`);
  };

  const handleAddCategoryClick = () => {
    if (!dealerId) {
      // Show snackbar if dealerId is not selected
      enqueueSnackbar('Please select a dealer first.', { variant: 'warning' });
    } else {
      // If dealerId is selected, navigate to the add offers page
      history.push('/category/add');
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4} className={classes.stepPadding} direction='column'>
          <Grid item xs={12}>
            <Toolbar disableGutters style={{ marginBottom: '2rem' }}>
              <Typography style={{ flex: '1 1' }} variant='h4' id='tableTitle' component='div'>
                Categories
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  name='categoryFilter'
                  id='categoryFilter'
                  label='Search Categories'
                  value={searchInput}
                  type='text'
                  InputProps={{
                    endAdornment: searchInput ? (
                      <InputAdornment position='end'>
                        <IconButton size='small' disableFocusRipple disableRipple onClick={() => setSearchInput('')}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null
                  }}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </form>
              <Select
                value={filterBy}
                onChange={handleFilterChange}
                displayEmpty
                className={classes.select}
                inputProps={{ 'aria-label': 'Filter by' }}
              >
                <MenuItem value="">All Types</MenuItem>
                <MenuItem value="make">Makes</MenuItem>
                <MenuItem value="offer">Offers</MenuItem>
              </Select>
              <Button
                onClick={handleAddCategoryClick}
                variant='contained'
                style={{ marginLeft: '2rem' }}
                startIcon={<AddIcon />}
                color='primary'
              >
                Category
              </Button>
            </Toolbar>

            <TableContainer>
              <Table aria-label='categories table' size='small'>
                <TableHeadersSort order={order} orderBy={orderBy} headCells={headCells} onRequestSort={handleRequestSort} />
                <TableBody>
                  {filterSearchCategory.map((row) => (
                    <Tooltip title={`Edit ${row.name}`} key={row.id} placement='left'>
                      <TableRow hover className={classes.hoverTableCell}>
                        <TableCell onClick={() => handleButtonClick(row.id)}>
                          <Typography style={{ color: row.status === 'inActive' ? '#ff5722' : '' }} variant='subtitle2'>
                            {row.name}
                          </Typography>
                        </TableCell>
                        <TableCell onClick={() => handleButtonClick(row.id)}>
                          {row.type}
                        </TableCell>
                        <TableCell onClick={() => handleButtonClick(row.id)}>
                          {row.description}
                        </TableCell>

                      </TableRow>
                    </Tooltip>
                  ))}
                  {!filteredCategories.length && !isLoading && (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align='center' style={{ borderBottom: 0 }} className='py-3'>
                        <Typography variant="h6" color="textSecondary">
                          {dealerId ? "No Categories Added" : "Select a dealer!"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {isLoading && (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align='center' style={{ borderBottom: 0 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                count={total}
                page={page}
                rowsPerPage={pageSize}
                component='div'
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePageSize}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
