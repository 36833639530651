import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import axios from "axios";
import TemplateInfo from "./TemplateInfo";
import { BASE_URI } from "../../shared/Constants";
import { DealerContext } from "../../shared/DealerContext";
import HomePageTemplate from "./TemplatePages/HomePageTemplate";
import NewVLP from "./TemplatePages/NewVLP";
import UsedVLP from "./TemplatePages/UsedVLP";
import UsedVDP from "./TemplatePages/UsedVDP";
import ServicePage from "./TemplatePages/Service";
import PartsPage from "./TemplatePages/Parts";
import FinancePage from "./TemplatePages/Finance";
import ContactUsPage from "./TemplatePages/ContactUs";
import BasePage from "./TemplatePages/BasePage";
import WebsitePages from "../websites/WebsitePages";
import { useParams } from "react-router-dom";
import { TemplateContext } from "../../shared/context/TemplateContext";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#3378af",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    stepPadding: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
}));

export default function TemplateContainer() {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const { id } = useParams();
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);
    const { selectedMotorGroup } = useContext(DealerContext);
    const { selectedOemTemplate } = useContext(TemplateContext);
    const [isAddMode, setIsAddMode] = useState(!id);

    useEffect(() => {
        if (!isAddMode) {
            const params = {
                dealerId: selectedMotorGroup?.id,
                templateId: id || selectedOemTemplate?.id
            };
            setLoading(true);
            axios
                .get(`${BASE_URI}/WebsitePages`, { params })
                .then((response) => {
                    setPages(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching tab names:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [selectedOemTemplate, id, selectedMotorGroup?.id, isAddMode]);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // Define tabs and their components
    const tabs = [
        { label: "Template Info", component: <TemplateInfo /> },
        { label: "Home Page", component: <HomePageTemplate page={pages.find(page => page.pageCategoryId === 1)} /> },
        { label: "New Vehicles", component: <NewVLP page={pages.find(page => page.pageCategoryId === 2)} /> },
        { label: "Used Vehicles", component: <UsedVLP page={pages.find(page => page.pageCategoryId === 3)} /> },
        { label: "Used Vehicle Details", component: <UsedVDP page={pages.find(page => page.pageCategoryId === 6)} /> },
        { label: "Service", component: <ServicePage page={pages.find(page => page.pageCategoryId === 9)} /> },
        { label: "Parts", component: <PartsPage page={pages.find(page => page.pageCategoryId === 8)} /> },
        { label: "Finance", component: <FinancePage page={pages.find(page => page.pageCategoryId === 10)} /> },
        { label: "Contact Us", component: <ContactUsPage page={pages.find(page => page.pageCategoryId === 7)} /> },
        { label: "Base Page", component: <BasePage page={pages.find(page => page.pageCategoryId === 0)} /> },
        { label: "Custom Pages", component: <WebsitePages /> }
    ];

    // Filter tabs based on isAddMode
    const visibleTabs = isAddMode ? tabs.slice(0, 1) : tabs;

    return (
        <>
            <AppBar position="relative" className={classes.root}>
                <Tabs
                    variant="scrollable"
                    value={selectedTab}
                    onChange={handleChange}
                    textColor="inherit"
                >
                    {visibleTabs.map((tab, index) => (
                        <Tab key={index} label={tab.label} />
                    ))}
                </Tabs>
            </AppBar>

            {visibleTabs[selectedTab] && visibleTabs[selectedTab].component}
        </>
    );
}
