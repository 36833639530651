import { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants';

export default function MessagingSettingsFilter(dealerId, searchString, pageNumber, pageSize, order, orderBy) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setData([]);
  }, [searchString, pageNumber, pageSize, order, orderBy, dealerId]);

  useEffect(() => {
    setLoading(true);
    setError(false);

    const params = {
      pageNumber: pageNumber + 1,
      pageSize,
      dealerId
    };

    if (searchString) {
      params.searchString = searchString;
    }
    if (order) {
      params.order = order.toUpperCase();
    }
    if (orderBy) {
      params.orderBy = orderBy;
    }

    axios({
      method: 'GET',
      url: `${BASE_URI}/messageTemplate`,
      params
    })
      .then((response) => {
        if (response.data.list) {
          setData(response.data.list);
        }
        setTotal(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          setError(true);
        }
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, pageNumber, pageSize, order, orderBy, dealerId]);

  return { loading, error, data, total };
}
