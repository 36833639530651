import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography'
import { CurrencyConverter, CurrencyValue } from '../../../shared/TextMask'
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { ImageContainer } from '../../../shared/ImageContainer'
import DeleteIcon from '@material-ui/icons/Delete';
import Zoom from '@material-ui/core/Zoom';
import DerivativeForm from './DerivativeForm'
import ConfirmationDialog from '../../../shared/ConfirmationDialog'
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import axios from 'axios'
import { BASE_URI } from '../../../shared/Constants'
import { useSnackbar } from 'notistack'
import { DealerContext } from '../../../shared/DealerContext';

const useStyles = makeStyles((theme) => ({
    gridList: {
        paddingTop: theme.spacing(4)
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    imgContainer: {
        cursor: 'pointer'
    }
}));

export default function DerivativeListContainer({ navigate, selectVariant, dealerModelId, modelId }) {
    const classes = useStyles();
    let history = useHistory();
    const [listView, setListView] = useState(true)
    const [formView, setFormView] = useState(false)
    const [activeDerivative, setActiveDerivative] = useState(null)
    const { dealerId } = useContext(DealerContext);
    const [openConfirm, setOpenConfirm] = useState(false)
    const [derivativeArr, setDerivativeArr] = useState([])
    const [loading, setLoading] = useState(true)
    const { enqueueSnackbar } = useSnackbar();
    const [forceReload, setForceReload] = useState(false);
    const [formType,setFormType]=useState('');

    useEffect(() => {
        setLoading(true)
        getVariants().then(() => {
            setLoading(false)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dealerModelId, forceReload])

    const getVariants = async () => {
        try {
            const result = await axios.get(`${BASE_URI}/dealervariants`, {
                params: {
                    dealermodelId: dealerModelId,
                    pageSize: 1000
                },
            })
            setDerivativeArr(result.data.list)
        } catch (error) {
            if (axios.isCancel(error)) return
            enqueueSnackbar("Unable to load vehicle variants", { variant: 'error' });
        }
    }

    const handleFormAction = (data) => {

        setFormView((prev) => !prev)

        if (data) {
            if (data.id !== undefined) {
                setLoading(true)
                editDerivativeCall(data).then((res) => {
                    getVariants();
                    setActiveDerivative(null)
                    setLoading(false)
                    enqueueSnackbar("Successfully edited vehicle variant", { variant: 'success' });
                }).catch((err) => {
                    enqueueSnackbar(err.response.data, { variant: 'error' });
                })
            } else {
                createDerivativeCall(data).then((res) => {
                    getVariants();
                    setActiveDerivative(null)
                    setLoading(false)
                    enqueueSnackbar("Successfully added vehicle variant", { variant: 'success' });
                }).catch((err) => {
                    enqueueSnackbar(err.response.data, { variant: 'error' });
                })
            }
        } else {
            enqueueSnackbar("No form data is submitted", { variant: 'error' });
        }
    }

    const editDerivative = (derivative) => {
        if (!dealerId) {
            // Show snackbar if dealerId is not selected
            enqueueSnackbar('Please select a dealer first.', { variant: 'warning' });
          } else {
            setFormType('editDerivative')
            setActiveDerivative(derivative)
            setListView((prev) => !prev)
          }
    }

    const formCancel = () => {
        setActiveDerivative(null)
        setFormView((prev) => !prev)
    }

    const addDerivative = () => {
        if (!dealerId) {
            // Show snackbar if dealerId is not selected
            enqueueSnackbar('Please select a dealer first.', { variant: 'warning' });
          } else {
            setFormType('addDerivative')
            setActiveDerivative(null)
            setListView((prev) => !prev)
          }
    }

    const deleteDerivative = () => {
        setOpenConfirm(false)
        if (activeDerivative) {
            setLoading(true)
            deleteVariant(activeDerivative.id).then((res) => {
                setLoading(false)
                enqueueSnackbar("Successfully deleted vehicle variant", { variant: 'success' });
            }).catch((err) => {
                setLoading(false)
                enqueueSnackbar("Unable to delete vehicle variant", { variant: 'error' });
            })
        }
    }

    const createDerivativeCall = (formData) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let data = { ...formData }

        data.priceRange= CurrencyConverter(formData.priceRange)
        data.title = formData.title
        data.subtitle = formData.subtitle
        data.highlights = formData.highlights
        data.variantId = formData.variantId
        data.dealerModelId = parseInt(dealerModelId, 10)
        data.name = formData.variant.variantName
        data.automaticPrice = CurrencyConverter(formData.automaticPrice)
        data.specSheet= formData.specSheet
        data.financeDetails= formData.financeDetails
        data.dealerId = dealerId

        return axios.post(`${BASE_URI}/dealervariants`, data, {
            params: {
                dealermodelId: dealerModelId
            },
            cancelToken: source.token
        })
    }

    const editDerivativeCall = (formData) => {
        let data = { ...formData }
        data.id = activeDerivative.id
        data.priceRange = CurrencyConverter(formData.priceRange)
        data.title = formData.title
        data.subtitle = formData.subtitle
        data.highlights = formData.highlights
        data.variantId = formData.variantId
        data.ownedModelId = parseInt(dealerModelId, 10)
        data.name = formData.variant.variantName
        data.automaticPrice = CurrencyConverter(formData.automaticPrice)
        data.specSheet = formData.specSheet
        data.financeDetails= formData.financeDetails
        return axios.put(`${BASE_URI}/dealervariants/${formData.id}`, data)
    }

    const deleteVariant = (id) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        return axios.delete(`${BASE_URI}/dealervariants/${id}`, {
            cancelToken: source.token
        }).then((v)=>{
            getVariants();
        })
    }

    return (
        <React.Fragment>
            <ConfirmationDialog secondaryInfo={activeDerivative ? activeDerivative.title : ''}
                action='delete'
                open={openConfirm}
                confirm={deleteDerivative}
                closeDialog={() => setOpenConfirm(false)} />
            <Zoom in={listView} mountOnEnter unmountOnExit
                onExited={() => setFormView((prev) => !prev)}>
                <div>
                    <Toolbar disableGutters>
                        <Typography style={{ flex: '1 1' }} variant="h4">
                            Variants
            </Typography>
                        <Button onClick={addDerivative}
                            variant="contained"
                            startIcon={<AddIcon />}
                            color="primary">
                            Variant
            </Button>
                    </Toolbar>
                    <GridList cols={3} cellHeight={180} className={classes.gridList}>
                        {derivativeArr.map((tile) => (
                            <GridListTile key={tile.id}>
                                <div
                                    className={classes.imgContainer}
                                    onClick={() => navigate('colours', modelId, dealerModelId, tile.id)}>
                                    <ImageContainer
                                        src={tile.image}
                                        alt={tile.title} />
                                </div>
                                <GridListTileBar
                                    title={tile.title}
                                    subtitle={
                                        <Grid container direction="column">
                                            <Grid item>
                                                {tile.variantName}
                                            </Grid>
                                            <Grid item>
                                                <CurrencyValue value={tile.priceRange} />
                                            </Grid>
                                        </Grid>
                                    }
                                    actionIcon={
                                        <React.Fragment>
                                            <IconButton aria-label={`Edit variant ${tile.variantName}`}
                                                onClick={() => {
                                                    selectVariant(tile.id);
                                                    editDerivative(tile)
                                                }}
                                                className={classes.icon}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    setActiveDerivative(tile)
                                                    setOpenConfirm(true)
                                                }}
                                                aria-label={`Delete variant ${tile.variantName}`} className={classes.icon}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </React.Fragment>
                                    }
                                />
                            </GridListTile>
                        ))}
                    </GridList>
                    {
                        (!derivativeArr.length && !loading) &&
                        <Grid container justify="center">
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h6" color="textSecondary">
                                    No vehicle variants available
                </Typography>
                            </Grid>
                        </Grid>
                    }
                    {
                        loading &&
                        <Grid container justify="center">
                            <Grid item xs={1}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    }
                </div>
            </Zoom>

            <Zoom in={formView} mountOnEnter unmountOnExit
                onExited={() => setListView((prev) => !prev)}>
                <div>
                    <DerivativeForm data={activeDerivative}
                        modelId={modelId}
                        onSubmit={handleFormAction}
                        formType={formType}
                        cancel={formCancel} />
                </div>
            </Zoom>

        </React.Fragment>
    )
}