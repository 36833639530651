import React, { useEffect, useState, useRef, useContext } from 'react';
import { Grid, TextField, Button, Box, Paper, Divider, InputLabel, TextareaAutosize, MenuItem } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { LoadingContext } from '../../shared/context/loadingContext';
import ImageUploadInput from '../../shared/ImageUploadFormInput';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { DealerContext } from '../../shared/DealerContext';
import { BASE_URI } from '../../shared/Constants';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  textArea: {
    fontFamily: 'monospace',
    padding: '10px',
    width: '100%',
    height: '200px !important',
    fontSize: '12px',
    overflowY: 'scroll',
    whiteSpace: 'pre-wrap',
    backgroundColor: '#f4f4f4',
    border: '1px solid #ccc',
    borderRadius: '5px'
  }
}));

export default function CategoryForm() {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [isAddMode, setIsAddMode] = useState(!id);
  const { enqueueSnackbar } = useSnackbar();
  const { dealerId } = useContext(DealerContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const mountedRef = useRef(true);

  const initialValues = {
    name: '',
    type: '',
    description: '',
    altTag: '',
    wideImageAltTag: ''
  };

  const [formFields, setFormFields] = useState(initialValues);
  const [categoryTypes, setCategoryTypes] = useState([
    { id: 1, name: 'Offer' },
    { id: 2, name: 'Make' }
    // Add more types as needed
  ]);

  useEffect(() => {
    const mountedRef = { current: true };
  
    const getCategory = async () => {
      try {
        showLoading();
        const result = await axios.get(`${BASE_URI}/Categories/${id}`);
        if (mountedRef.current) {
          setFormFields(result.data);
        }
      } catch (error) {
        if (mountedRef.current) {
          enqueueSnackbar('Unable to get category details', { variant: 'error' });
        }
      } finally {
        if (mountedRef.current) {
          hideLoading();
        }
      }
    };
  
    if (!isAddMode) {
      getCategory();
    }
  
    return () => {
      mountedRef.current = false; 
    };
  }, []);
  

  const getErrorMessage = (axiosError, defaultMsg) => {
    let errorTitle = defaultMsg;
    let errorDetails = 'No additional details are available';

    if (axiosError?.request?.message || axiosError?.request?.statusText) {
      errorTitle = 'Failed to send the request';
      errorDetails = axiosError?.request?.message ?? axiosError?.request?.statusText;
    } else if (axiosError?.response?.data) {
      errorTitle = axiosError?.response?.data?.title ?? errorTitle;
      errorDetails = axiosError?.response?.data?.details ?? errorDetails;
    }

    return `${errorTitle}: ${errorDetails}`;
  };

  const editCategory = async (id, fields, setSubmitting, resetForm) => {
    try {
      await axios.put(`${BASE_URI}/Categories/${id}`, fields);
      enqueueSnackbar('Successfully saved the category.', { variant: 'success' });
      history.push('/categories');
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error, 'Failed to save the category'), { variant: 'error' });
    } finally {
      hideLoading();
      setSubmitting(false);
    }
  };

  const createCategory = async (fields, setSubmitting, resetForm) => {
    try {
      await axios.post(`${BASE_URI}/Categories`, fields);
      enqueueSnackbar('Successfully saved the category.', { variant: 'success' });
      setIsAddMode(false);
      history.push('/categories');
    } catch (error) {
      enqueueSnackbar(`Failed to save category: ${error.message}`, { variant: 'error' });
    } finally {
      hideLoading();
      setSubmitting(false);
    }
  };

  const onSubmit = (fields, { setStatus, setSubmitting, resetForm }) => {
    setStatus();
    showLoading();

    if (!dealerId) {
      enqueueSnackbar('Dealer must be selected first', { variant: 'warning' });
      hideLoading();
      setSubmitting(false);
      return;
    }

    fields.dealerId = dealerId;

    const categoryImage = fields.image?.imageUrl;
    fields.image.imageUrl = categoryImage.image || categoryImage;
    fields.image.altTag = fields.altTag || fields.image.altTag;

    const categoryWideImage = fields.wideImage?.imageUrl;
    fields.wideImage.imageUrl = categoryWideImage.image || categoryWideImage;
    fields.wideImage.altTag = fields.wideImageAltTag || fields.wideImage.altTag;

    if (isAddMode) {
      createCategory(fields, setSubmitting, resetForm);
    } else {
      editCategory(id, fields, setSubmitting, resetForm);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={4} className={classes.stepPadding} direction='column'>
        <Grid item xs={12}>
          <Formik
            initialValues={formFields}
            onSubmit={onSubmit}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .min(3, 'Name must be at least 3 characters long')
                .required('Name is required'),
              type: Yup.string()
                .oneOf(['Offer', 'Make'], 'Please select a valid type')
                .required('Category type is required'),
            })}
            enableReinitialize={true}
          >
            {props => {
              const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset, setFieldValue } = props;

              return (
                <Form noValidate>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid spacing={4} container direction='row'>
                      <Grid item spacing={4} container xs={12}>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            name='name'
                            id='name'
                            required
                            label='Name'
                            value={values.name}
                            type='text'
                            helperText={errors.name && touched.name ? errors.name : ''}
                            error={errors.name && touched.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <TextField
                            fullWidth
                            id="type"
                            select
                            label="Type"
                            name="type"
                            value={values.type}  
                            helperText={touched.type && errors.type ? errors.type : ''}
                            error={touched.type && !!errors.type}  
                            onBlur={handleBlur}
                            onChange={(e) => setFieldValue('type', e.target.value) }
                          >
                            {categoryTypes.map((option) => (
                              <MenuItem key={option.id} value={option.name}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>

                          <InputLabel htmlFor="description">Description</InputLabel>
                          <TextareaAutosize
                            id="description"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={classes.textArea}
                            minRows={4}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ImageUploadInput
                            title='Category Image'
                            required
                            fieldName={'image.imageUrl'}
                            error={errors.image && touched.image}
                          />
                          <TextField
                            fullWidth
                            name='altTag'
                            id='altTag'
                            label='Alt Tag'
                            value={values.altTag ?? values.image?.altTag}
                            type='text'
                            helperText={errors.altTag && touched.altTag ? errors.altTag : 'Alt tag for SEO'}
                            error={errors.altTag && touched.altTag}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ paddingTop: '0px' }}>
                          <ImageUploadInput
                            title='Wide Image'
                            required
                            fieldName={'wideImage.imageUrl'}
                            error={errors.wideImage && touched.wideImage}
                          />
                          <TextField
                            fullWidth
                            name='wideImageAltTag'
                            id='wideImageAltTag'
                            label='Alt Tag'
                            value={values.wideImageAltTag ?? values.wideImage?.altTag}
                            type='text'
                            helperText={errors.wideImageAltTag && touched.wideImageAltTag ? errors.wideImageAltTag : 'Alt tag for SEO'}
                            error={errors.wideImageAltTag && touched.wideImageAltTag}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider variant='fullWidth' />
                      </Grid>

                      <Grid item container justify='flex-end' xs={12}>
                        <Box mr={3}>
                          <Button
                            onClick={() => {
                              handleReset();
                              history.push('/categories');
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                        <Button type='submit' variant='contained' color='primary' disabled={isSubmitting}>
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
}
