import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TableHeadersSort from '../../shared/TableHeadersSort'
import {DEFAULT_PAGE_SIZE} from '../../shared/Constants'
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import ExportContainer from '../../shared/ExportContainer'
import MessagingSettingsFilter from './MessagingSettingsFilter'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { DealerContext } from '../../shared/DealerContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  green: {
    color: '#8bc34a'
  },
  hoverTableCell: {
    '&:hover': {
      cursor: 'pointer',
    }
  }
}));

export default function MessagingSettingsTable() {
  const classes = useStyles();
  let history = useHistory();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('type');
  const { dealerId } = useContext(DealerContext);
  const [searchString, setSearchString] = useState('');
  const [actionFilter, setActionFilter] = useState('');
  const{
    loading,
    data,
    total
  }= MessagingSettingsFilter(dealerId,searchString, page, pageSize, order, orderBy)

  const headCells = [
    { id: 'type', label: 'Type', sort: true },
    { id: 'action', label: 'Action', sort: true },
    { id: 'state', label: 'State', sort: true },
    { id: 'motorGroup', label: 'Motorgroup', sort: true },
    { id: 'subject', label: 'Subject', sort: true, width: '50%' }
  ];

  const actionsArr = [
    'Voice Message', 'Appointment', 'State Change', 'Hand Over', 'Finance link'
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const handleChange = (event) => {
    setSearchString(event.target.value)
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4} className={classes.stepPadding} direction="column">
          <Grid item xs={12}>
            <Toolbar disableGutters style={{marginBottom: '2rem'}}>
              <Typography style={{flex: '1 1'}} variant="h4" id="tableTitle" component="div">
                Email & SMS Wording
              </Typography>
                <Button onClick={() => history.push('/admin/messaging/add')}
                        variant="contained"
                        style={{marginLeft: '2rem'}}
                        startIcon={<AddIcon />}
                        color="primary">
                  Communications Content
                </Button>

            </Toolbar>

            <Grid item container style={{marginBottom: '1rem'}} justify="space-between" xs={12}>
              <div>
              <ExportContainer values={data}
                               useDataHeaders
                               fileName='SMS&Email_Wording'
                               headers={headCells}/>
              </div>
              <div>
                <TextField
                  style={{marginRight: '1rem', width: '200px'}}
                  name="filterActions"
                  id="filterActions"
                  label="Actions"
                  select
                  value={actionFilter}
                  onChange={(e) => setActionFilter(e.target.value)}
                >
                  {actionsArr.map((option, index) => (
                    <MenuItem key={index} id={`${option}-${index}`} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  name="filter"
                  id="filter"
                  label="Search State"
                  value={searchString}
                  type="text"
                  InputProps={{
                    endAdornment: searchString ?  (
                      <InputAdornment position="end">
                        <IconButton size="small" disableFocusRipple
                                    disableRipple onClick={()=> setSearchString('')}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ): '',
                  }}
                  onChange={handleChange}
                />
              </div>
            </Grid>

            <TableContainer>
              <Table aria-label="message settings table" size="small">
                <TableHeadersSort
                  order={order}
                  orderBy={orderBy}
                  headCells={headCells}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {
                    data.map((row) =>(
                      <Tooltip title={`Edit`} key={row.id} placement="left">
                        <TableRow hover
                                className={classes.hoverTableCell}
                                onClick={() => history.push(`/admin/messaging/edit/${row.id}`)}>
                        <TableCell size="small">

                            <div style={{display: 'flex'}}>
                              {
                                row.type === 'email' ? <MailOutlineIcon />: <PhoneAndroidIcon/>
                              }
                              <Typography variant="subtitle2" style={{marginLeft: '0.5rem'}}>
                                {row.type}
                              </Typography>
                            </div>
                        </TableCell>
                        <TableCell>
                          {row.action}
                        </TableCell>
                        <TableCell>
                          {row.state}
                        </TableCell>
                        <TableCell>
                          {row.motorgroup}
                        </TableCell>
                        <TableCell>
                          {row.subject}
                        </TableCell>
                      </TableRow>
                      </Tooltip>
                    ))
                  }
                  {
                    ((!data.length || data.length === 0) && !loading) &&
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center"
                                 style={{borderBottom: 0}}
                                 className="py-3">
                        <Typography variant="h6" color="textSecondary">
                          No Communications Content Added
                        </Typography>
                      </TableCell>
                    </TableRow>
                  }
                  {
                    loading &&
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center"
                                 style={{borderBottom: 0}}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={total}
                page={page}
                rowsPerPageOptions={[5,10,25]}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}