import React from 'react';
import { Grid, TextField, Button, Box, Divider } from '@material-ui/core';
import { Formik, Form } from 'formik';
import ImageUploadInput from '../../shared/ImageUploadFormInput';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useHistory } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import { ColorPicker } from 'material-ui-color';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fafafa',
  },
  colorPicker: {
    marginBottom: theme.spacing(3),
  },
  label: {
    display: 'block',
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
  },
  buttonGroup: {
    marginTop: theme.spacing(4),
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
}));

export default function ColourStyling({ initialValues, onSubmit }) {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid container spacing={4} direction="column">
      <Grid item xs={12}>
        <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit}>
          {(props) => {
            const {
              values,
              handleBlur,
              handleChange,
              isSubmitting,
              setFieldValue,
              errors,
              touched,
            } = props;

            const handleColorChange = (field) => (color) => {
              setFieldValue(field, `#${color.hex}`);
            };

            return (
              <Form noValidate className={classes.root}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container spacing={4}>
                    
                    {/* Left Column: Primary Colors and Image */}
                    <Grid item md={6} xs={12}>
                      <Box className={classes.colorPicker}>
                        <label htmlFor="colorPickerPrimary" className={classes.label}>
                          Primary Font Color:
                        </label>
                        <ColorPicker
                          id="colorPickerPrimary"
                          value={values.priFontCol}
                          onChange={handleColorChange('priFontCol')}
                        />
                      </Box>
                      <Box className={classes.colorPicker}>
                        <label htmlFor="colorPickerPrimaryBg" className={classes.label}>
                          Primary BG Color:
                        </label>
                        <ColorPicker
                          id="colorPickerPrimaryBg"
                          value={values.priBackground}
                          onChange={handleColorChange('priBackground')}
                        />
                      </Box>

                      <Box className={classes.colorPicker}>
                        <label htmlFor="colorPickerSecondary" className={classes.label}>
                          Secondary Font Color:
                        </label>
                        <ColorPicker
                          id="colorPickerSecondary"
                          value={values.secFontCol}
                          onChange={handleColorChange('secFontCol')}
                        />
                      </Box>
                      <Box className={classes.colorPicker}>
                        <label htmlFor="colorPickerSecondaryBg" className={classes.label}>
                          Secondary BG Color:
                        </label>
                        <ColorPicker
                          id="colorPickerSecondaryBg"
                          value={values.secBackground}
                          onChange={handleColorChange('secBackground')}
                        />
                      </Box>

                      <Box className={classes.colorPicker}>
                        <label htmlFor="colorPickerMute" className={classes.label}>
                          Mute Font Color:
                        </label>
                        <ColorPicker
                          id="colorPickerMute"
                          value={values.muFontCol}
                          onChange={handleColorChange('muFontCol')}
                        />
                      </Box>
                      <Box className={classes.colorPicker}>
                        <label htmlFor="colorPickerMuteBg" className={classes.label}>
                          Mute BG Color:
                        </label>
                        <ColorPicker
                          id="colorPickerMuteBg"
                          value={values.muBackground}
                          onChange={handleColorChange('muBackground')}
                        />
                      </Box>
                    </Grid>

                    {/* Right Column: Headings and Image Upload */}
                    <Grid item md={6} xs={12}>
                      <ImageUploadInput
                        title="Site Image"
                        required
                        fieldName={"siteImage.imageUrl"}
                        imageUrl={values.siteImage?.imageUrl}
                        error={errors.siteImage && touched.siteImage}
                      />
                      <TextField
                        fullWidth
                        className={classes.formControl}
                        name="siteImageAltTag"
                        id="siteImageAltTag"
                        label="Alt Tag"
                        value={values.siteImageAltTag || values.siteImage?.altTag}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <TextField
                        fullWidth
                        className={classes.formControl}
                        name="headingH1"
                        id="headingH1"
                        label="Heading H1"
                        value={values.headingH1}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <TextField
                        fullWidth
                        className={classes.formControl}
                        name="headingH2"
                        id="headingH2"
                        label="Heading H2"
                        value={values.headingH2}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <TextField
                        fullWidth
                        className={classes.formControl}
                        name="headingH3"
                        id="headingH3"
                        label="Heading H3"
                        value={values.headingH3}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <TextField
                        fullWidth
                        className={classes.formControl}
                        name="headingH4"
                        id="headingH4"
                        label="Heading H4"
                        value={values.headingH4}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <TextField
                        fullWidth
                        className={classes.formControl}
                        name="headingH5"
                        id="headingH5"
                        label="Heading H5"
                        value={values.headingH5}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <TextField
                        fullWidth
                        className={classes.formControl}
                        name="headingH6"
                        id="headingH6"
                        label="Heading H6"
                        value={values.headingH6}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>

                    {/* Divider */}
                    <Grid item xs={12}>
                      <Divider variant="fullWidth" />
                    </Grid>

                    {/* Button Group */}
                    <Grid item container justifyContent="flex-end" xs={12} className={classes.buttonGroup}>
                      <Box mr={3}>
                        <Button onClick={() => history.push('/templates')}>Cancel</Button>
                      </Box>
                      <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}
