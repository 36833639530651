import React from "react";

export default function incomeTable() {
  return [
    {
      id: "grossIncome",
      label: "Gross Income",
      applicant: "grossIncome",
      spouse: "grossIncome",
      adornment: "R",
    },
    {
      id: "salaryIncome",
      label: "Net Salary",
      applicant: "netSalary",
      spouse: "netSalary",
      adornment: "R",
    },
    {
      id: "commissionApplicant",
      label: "Commission",
      applicant: "earningsCommission",
      spouse: "earningsCommission",
      adornment: "R",
    },
    {
      id: "carAllowanceApplicant",
      label: "Car Allowance",
      applicant: "earningsCarAllowance",
      spouse: "earningsCarAllowance",
      adornment: "R",
    },
    {
      id: "overtimeApplicant",
      label: "Overtime",
      applicant: "earningsOvertime",
      spouse: "earningsOvertime",
      adornment: "R",
    },
    {
      id: "otherAllowanceApplicant",
      label: "Other Allowance",
      applicant: "earningsOtherAllowance",
      spouse: "earningsOtherAllowance",
      adornment: "R",
    },
    {
      id: "otherIncomeApplicant",
      label: "Other Income",
      applicant: "earningsOtherIncome",
      spouse: "earningsOtherIncome",
      adornment: "R",
    },
    {
      id: "rentalIncomeApplicant",
      label: "Rental income",
      applicant: "earningsRentalIncome",
      spouse: "earningsRentalIncome",
      adornment: "R",
    },
    {
      id: "maintenanceIncomeApplicant",
      label: "Maintenance Income",
      applicant: "earningsMaintenanceIncome",
      spouse: "earningsMaintenanceIncome",
      adornment: "R",
    },
    {
      id: "reimbursement",
      label: "Re-Imbursement",
      applicant: "earningsReimbursement",
      spouse: "earningsReimbursement",
      adornment: "R",
    },
  ];
}

export function incomeUnmarriedTable() {
  return [
    {
      id: "grossIncome",
      label: "Gross Income",
      applicant: "grossIncome",
      spouse: "grossIncome",
      adornment: "R",
    },
    {
      id: "salaryIncome",
      label: "Net Salary",
      applicant: "netSalary",
      spouse: "netSalary",
      adornment: "R",
    },
    {
      id: "commissionApplicant",
      label: "Commission",
      applicant: "earningsCommission",
      spouse: "earningsCommission",
      adornment: "R",
    },
    {
      id: "carAllowanceApplicant",
      label: "Car Allowance",
      applicant: "earningsCarAllowance",
      spouse: "earningsCarAllowance",
      adornment: "R",
    },
    {
      id: "overtimeApplicant",
      label: "Overtime",
      applicant: "earningsOvertime",
      spouse: "earningsOvertime",
      adornment: "R",
    },
    {
      id: "otherAllowanceApplicant",
      label: "Other Allowance",
      applicant: "earningsOtherAllowance",
      spouse: "earningsOtherAllowance",
      adornment: "R",
    },
    {
      id: "otherIncomeApplicant",
      label: "Other Income",
      applicant: "earningsOtherIncome",
      spouse: "earningsOtherIncome",
      adornment: "R",
    },
    {
      id: "rentalIncomeApplicant",
      label: "Rental income",
      applicant: "earningsRentalIncome",
      spouse: "earningsRentalIncome",
      adornment: "R",
    },
    {
      id: "maintenanceIncomeApplicant",
      label: "Maintenance Income",
      applicant: "earningsMaintenanceIncome",
      spouse: "earningsMaintenanceIncome",
      adornment: "R",
    },
    {
      id: "reimbursement",
      label: "Re-Imbursement",
      applicant: "earningsReimbursement",
      spouse: "earningsReimbursement",
      adornment: "R",
    },
    {
      id: "account",
      label: "Account",
      applicant: "account",
      spouse: "account",
      adornment: "R",
    },
    {
      id: "savings",
      label: "Savings",
      applicant: "savings",
      spouse: "savings",
      adornment: "R",
    },
    {
      id: "incomeFrequency",
      label: "Income Frequency",
      applicant: "incomeFrequency",
      spouse: "incomeFrequency",
      adornment: "R",
    },
    {
      id: "salaryDay",
      label: "Salary Day",
      applicant: "salaryDay",
      spouse: "salaryDay",
      adornment: "Day",
    }
  ];
}


export function expenseTable() {
  return [
    {
      id: "rentApplicant",
      label: "Rent/ Bond",
      applicant: "bond",
      spouse: "bond",
      adornment: "R",
    },
    {
      id: "utilitiesApplicant",
      label: "Utilities",
      applicant: "utilities",
      spouse: "utilities",
      adornment: "R",
    },
    {
      id: "personalLoanApplicant",
      label: "Personal Loan",
      applicant: "personalLoan",
      spouse: "personalLoan",
      adornment: "R",
    },
    {
      id: "personalWorkLoanApplicant",
      label: "Loan from Payslip",
      applicant: "loansFromPayslip",
      spouse: "loansFromPayslip",
      adornment: "R",
    },
    {
      id: "creditCardApplicant",
      label: "Credit Card",
      applicant: "creditCard",
      spouse: "creditCard",
      adornment: "R",
    },
    {
      id: "transportApplicant",
      label: "Transport",
      applicant: "transport",
      spouse: "transport",
      adornment: "R",
    },
    {
      id: "telephoneApplicant",
      label: "Telephone",
      applicant: "telephone",
      spouse: "telephone",
      adornment: "R",
    },
    {
      id: "entertainmentApplicant",
      label: "Entertainment",
      applicant: "entertainment",
      spouse: "entertainment",
      adornment: "R",
    },
    {
      id: "educationApplicant",
      label: "Education",
      applicant: "education",
      spouse: "education",
      adornment: "R",
    },
    {
      id: "furnitureApplicant",
      label: "Furniture",
      applicant: "furnitureAccount",
      spouse: "furnitureAccount",
      adornment: "R",
    },
    {
      id: "clothingApplicant",
      label: "Clothing",
      applicant: "clothing",
      spouse: "clothing",
      adornment: "R",
    },
    {
      id: "policyApplicant",
      label: "Policy and Insurance",
      applicant: "policy",
      spouse: "policy",
      adornment: "R",
    },
    {
      id: "overdraftApplicant",
      label: "Overdraft",
      applicant: "overdraft",
      spouse: "overdraft",
      adornment: "R",
    },
    {
      id: "maintenanceApplicant",
      label: "Maintenance",
      applicant: "maintenance",
      spouse: "maintenance",
      adornment: "R",
    },
    {
      id: "householdApplicant",
      label: "Food & Household",
      applicant: "foodAndHousehold",
      spouse: "foodAndHousehold",
      adornment: "R",
    },
    {
      id: "vehicleApplicant",
      label: "Vehicle",
      applicant: "vehicle",
      spouse: "vehicle",
      adornment: "R",
    },
    {
      id: "municipalApplicant",
      label: "Municipal Rates",
      applicant: "municipalRates",
      spouse: "municipalRates",
      adornment: "R",
    },
    {
      id: "otherApplicant",
      label: "Other",
      applicant: "other",
      spouse: "other",
      adornment: "R",
    }
  ];
}
