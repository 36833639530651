import React, { useEffect, useState, useRef, useContext } from 'react';
import { Grid, TextField, Button, Box, MenuItem, Checkbox, FormControlLabel, Typography, Paper, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useSnackbar } from 'notistack'; // Import the useSnackbar hook
import { BASE_URI } from '../../shared/Constants';
import { DealerContext } from '../../shared/DealerContext';
 
const useStyles = makeStyles((theme) => ({
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  title: {
    fontSize: 16
  }
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

export default function MessagingSettingsForm({ history, match }) {
  const classes = useStyles();
  const { id } = match.params;
  const isAddMode = !id;
  const mountedRef = useRef(true);
  const { dealerId } = useContext(DealerContext);
  const { enqueueSnackbar } = useSnackbar(); 

  const initialValues = {
    messageTypeId: 0,
    leadStatusId: 0,
    messageActionId: 0,
    subject: '',
    body: '',
    isLinkedWithForm: false
  };

  const [formFields, setFormFields] = useState(initialValues);
  const [types, setTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    const getMessageSettings = async () => {
      try {
        const result = await axios.get(`${BASE_URI}/messageTemplate/${id}`);
        let formData = result.data;
        setFormFields(formData);
      } catch (error) {
        history.push('..');
      }
    };

    if (!isAddMode) {
      getMessageSettings().then(() => {
        if (!mountedRef.current) return null;
      });
    }

    axios.get(`${BASE_URI}/messageType`).then((response) => {
      setTypes(response.data);
    });

    axios.get(`${BASE_URI}/leadStatus`).then((response) => {
      setStates(response.data);
    });

    axios.get(`${BASE_URI}/messageAction`).then((response) => {
      setActions(response.data);
    });

    return () => {
      mountedRef.current = false;
    };
  }, [id, isAddMode, history]);

  const mailMergeFieldArr = [
    { variable: '[[LEAD_NAME]]', value: 'Leads Name' },
    { variable: '[[LEAD_PHONE]]', value: 'Leads Phone' },
    { variable: '[[LEAD_EMAIL]]', value: 'Leads Email' },
    { variable: '[[PREQUAL_URL]]', value: 'Link to pre-qualification form' },
    { variable: '[[SUBJECT]]', value: 'Subject of the enquiry' },
    { variable: '[[NOW]]', value: 'Current date and time' },
    { variable: '[[DEALER]]', value: 'Dealer Name' },
    { variable: '[[MOTORGROUP]]', value: 'Motogroup Name' },
    { variable: '[[VEHICLE]]', value: 'Vehicle make and model' },
    { variable: '[[APPOINTMENT]]', value: 'Appointment start date and time' },
    { variable: '[[AGENT_NAME]]', value: 'Agents Name' },
    { variable: '[[AGENT_PHONE]]', value: 'Agents Phone' },
    { variable: '[[AGENT_EMAIL]]', value: 'Agent Email' },
    { variable: '[[FINANCE_URL]]', value: 'Link to application form' }
  ];

  const updateMessageSettings = async (id, fields, setSubmitting, resetForm) => {
    try {
      const result = await axios.put(`${BASE_URI}/messageTemplate/${id}`, { ...fields, dealerId });
      resetForm();
      history.push('..');
      enqueueSnackbar('Message template updated successfully!', { variant: 'success' }); // Show success message
      return result;
    } catch (error) {
      enqueueSnackbar('Failed to update message template.', { variant: 'error' }); // Show error message
    } finally {
      setSubmitting(false);
    }
  };

  const createMessageSettings = async (fields, setSubmitting, resetForm) => {
    try {
      const result = await axios.post(`${BASE_URI}/messageTemplate`, { ...fields, dealerId });
      resetForm();
      history.push('/admin/messaging/');
      enqueueSnackbar('Message template created successfully!', { variant: 'success' }); // Show success message
      return result;
    } catch (error) {
      enqueueSnackbar('Failed to create message template.', { variant: 'error' }); // Show error message
    } finally {
      setSubmitting(false);
    }
  };

  function onSubmit(fields, { setStatus, setSubmitting, resetForm }) {
    setStatus();
    if (isAddMode) {
      createMessageSettings(fields, setSubmitting, resetForm);
    } else {
      updateMessageSettings(id, fields, setSubmitting, resetForm);
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={4} className={classes.stepPadding} direction="column">
        <Grid item xs={12}>
          <Formik
            initialValues={formFields}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              // Add any needed validation schema here
            })}
          >
            {(props) => {
              const { values, touched, errors, handleBlur, handleChange, isSubmitting, handleReset } = props;

              return (
                <Form>
                  <Typography variant="h6" gutterBottom>
                    {isAddMode ? 'Add' : 'Edit'} Communications Content
                  </Typography>
                  <Grid container spacing={4} style={{ marginTop: '0.5rem' }} direction="row">
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name="messageTypeId"
                        id="messageTypeId"
                        required
                        label="Type"
                        select
                        value={values.messageTypeId}
                        helperText={errors.messageTypeId && touched.messageTypeId ? errors.messageTypeId : ''}
                        error={errors.messageTypeId && touched.messageTypeId}
                        onBlur={handleBlur('messageTypeId')}
                        onChange={handleChange('messageTypeId')}
                      >
                        {types.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name="leadStatusId"
                        id="leadStatusId"
                        required
                        label="State"
                        select
                        value={values.leadStatusId}
                        helperText={errors.leadStatusId && touched.leadStatusId ? errors.leadStatusId : ''}
                        error={errors.leadStatusId && touched.leadStatusId}
                        onBlur={handleBlur('leadStatusId')}
                        onChange={handleChange('leadStatusId')}
                      >
                        {states.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        name="messageActionId"
                        id="messageActionId"
                        required
                        label="Actions"
                        select
                        value={values.messageActionId}
                        helperText={errors.messageActionId && touched.messageActionId ? errors.messageActionId : ''}
                        error={errors.messageActionId && touched.messageActionId}
                        onBlur={handleBlur('messageActionId')}
                        onChange={handleChange('messageActionId')}
                      >
                        {actions.map((option, index) => (
                          <MenuItem key={index} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                   
                    <Grid item container xs={12} md={7}>
                      <Grid item xs={12}>
                        <TextField
                          name="name"
                          required
                          fullWidth
                          id="name"
                          label="Email Template Name"
                          value={values.name}
                          helperText={errors.name && touched.name ? errors.name : ''}
                          error={errors.name && touched.name}
                          onBlur={handleBlur('name')}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="subject"
                          required
                          fullWidth
                          id="subject"
                          label="Subject / Short Text (e.g. SMS / WhatsApp)"
                          value={values.subject}
                          helperText={errors.subject && touched.subject ? errors.subject : ''}
                          error={errors.subject && touched.subject}
                          onBlur={handleBlur('subject')}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isLinkedWithForm}
                              onChange={handleChange('isLinkedWithForm')}
                              onBlur={handleBlur('isLinkedWithForm')}
                              name="isLinkedWithForm"
                              color="primary"
                            />
                          }
                          label="Is linked with form?"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="body"
                          id="body"
                          required
                          variant="outlined"
                          multiline
                          minRows={18}
                          label="Body"
                          value={values.body}
                          helperText={errors.body && touched.body ? errors.body : ''}
                          error={errors.body && touched.body}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Card>
                        <CardContent>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Mail Merge Fields
                          </Typography>
                          <TableContainer>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Variable</TableCell>
                                  <TableCell>Value</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {mailMergeFieldArr.map((row, index) => (
                                  <StyledTableRow key={index}>
                                    <TableCell>{row.variable}</TableCell>
                                    <TableCell>{row.value}</TableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item container justify="flex-end" xs={12}>
                      <Box mr={3}>
                        <Button
                          onClick={() => {
                            handleReset();
                            history.push('/admin/messaging');
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                      <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
}
