import React, { useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Grid,
  Button,
  Box,
  Switch,
  FormControlLabel,
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DealerContext } from '../../../shared/DealerContext';
import { TextField as TextFieldFormik } from 'formik-material-ui';
import { BASE_URI } from '../../../shared/Constants';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import ImageUploadInput from '../../../shared/ImageUploadFormInput';
import { LoadingContext } from '../../../shared/context/loadingContext';
import { useHistory, useParams } from 'react-router-dom';

const initialValues = {
  banner: false,
  headline: false,
  hours:false,
  contactDetails:false,
  contactForm: false,
  team:false,

  includePage: false,
  pageTitle: '',
  pageDescription: '',
  url: '',
  htmlModule1:false,
  htmlModule2:false,

  title: '',
  description: '',

  bannerImage: '',  

  // Additional fields for multiline text areas
  headlineText:'',
  contactDetailsText:'',
  contactFormText:'',
  hoursText: '',
  teamText: '',
  htmlModule1Text:'',
  htmlModule2Text:''
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  dynamicComponentCell: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const ContactUsPage = (props) => {
  const { page } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedMotorGroup } = useContext(DealerContext);
  const parsedObj = page?.templateConfig ? JSON.parse(page.templateConfig) : initialValues;
  const { id } = useParams();
  const { showLoading, hideLoading } = useContext(LoadingContext);
  let history = useHistory();

  const isBase64 = (str) => {
    const base64Pattern = /^data:image\/(jpeg|png|gif|bmp|webp);base64,/i;
    return base64Pattern.test(str);
  };

  const onSubmitUpdatePage = async (fields) => {
    try {
      showLoading();
    const imgUrl = await processImages(fields);
      fields.image = imgUrl;
      fields.bobPayload = transformToBobPayload(fields);
      if (selectedMotorGroup.id) {
        if (page != undefined || page != null) {
          const pageId = page.id;
          page.templateConfig = JSON.stringify(fields);
          page.url = fields.url;
          page.title = fields.title;
          page.description = fields.description;
          page.content = fields.content;
          axios
            .put(`${BASE_URI}/WebsitePages/${pageId}`, {
              ...page,
              id: pageId
            })
            .then((result) => {
              enqueueSnackbar('Updated contact page', { variant: 'success' });
              hideLoading();
            })
            .catch((error) => {
              enqueueSnackbar('Operation failed', { variant: 'error' });
              hideLoading();
            });
        } else {
          const newPage = {
            url: fields.url,
            title: fields.title,
            description: fields.description,
            content: '',
            templateConfig: JSON.stringify(fields),
            dealerID: selectedMotorGroup.id,
            websiteTemplatesId: parseInt(id),
            pageCategoryId: 7
          };
          axios
            .post(`${BASE_URI}/WebsitePages`, {
              ...newPage
            })
            .then((result) => {
              enqueueSnackbar('created contact page', { variant: 'success' });
              history.push('/templates');
              hideLoading();
            })
            .catch((error) => {
              enqueueSnackbar('Operation failed', { variant: 'error' });
              hideLoading();
            });
        }
      } else {
        enqueueSnackbar('Select a dealer first', { variant: 'warning' });
        hideLoading();
      }
    } catch (error) {
      enqueueSnackbar('Operation failed', { variant: 'error' });
    }
  };

  const processImages = async (fields) => {
    try {
      let image = fields.bannerImage;  
      let imageInput = [];

      if (isBase64(image?.image)) {
        imageInput.push(fields.bannerImage);  

        const res = await axios.put(`${BASE_URI}/Bob/uploadImage`, imageInput);
        let imgUrls = res.data;

        imgUrls.forEach((url) => {
          if (url.toLowerCase().includes(image.fileName.toLowerCase())) {
            image.image = url;
          }
        });
      } else {
        console.log('The image is not base64 encoded.');
      }

      return image;
    } catch (error) {
      enqueueSnackbar('Operation failed', { variant: 'error' });
      console.error('Error in processImages:', error);
    }
  };

  const transformToBobPayload = (values) => {
    const layoutMap = new Map();
  
    const addToLayoutMap = (partial, order, includes, params = []) => {
      if (includes || params.length > 0) {
        if (!layoutMap.has(partial)) {
          layoutMap.set(partial, { partial, order, includes, type: 'default', params });
        } else {
          const existing = layoutMap.get(partial);
          existing.includes = existing.includes || includes;
          existing.params = [...new Map([...existing.params, ...params].map(item => [item.key, item])).values()];
        }
      }
    };
  
    Object.keys(values).forEach((field) => {
      if (['includePage', 'title', 'description', 'url', 'bobPayload'].includes(field)) {
        return;
      }
  
      const includes = values[field] ? true : false;
      const order = values[`${field}Order`] || 0;
      let params = [];
  
      if (field === 'banner' && values.banner) {
        if (values.bannerImage) {
          params.push({ key: 'bannerImage', value: values.bannerImage });
        }
        addToLayoutMap('banner', order, includes, params);
      } else if (!['banner', 'bannerImage'].includes(field) && !field.endsWith('Text') && !field.endsWith('Order')) {
        if (values[`${field}Text`] !== undefined) {
          params.push({ key: `${field}Text`, value: values[`${field}Text`] });
        }
        addToLayoutMap(field, order, includes, params);
      }
    });
  
    const layout = Array.from(layoutMap.values());
  
    return {
      layout,
      page: page?.title || ''
    };
  };


  return (
    <Paper className={classes.paper}>
      <Toolbar>
        <Typography variant='h6' component='div'>
          Contact us
        </Typography>
      </Toolbar>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Formik
            initialValues={parsedObj}
            onSubmit={(values) => {
              onSubmitUpdatePage(values);
            }}
          >
            {({ touched, values, errors, handleBlur, handleChange, setFieldValue }) => {
              const handleChangeCustom = (fieldName, checked) => {
                setFieldValue(fieldName, checked);
              };
              return (
                <Form>
                  <TableContainer className={classes.tableContainer}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Module</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Include</TableCell>
                          <TableCell>Content</TableCell>
                          <TableCell>Order</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      <TableRow>
                          <TableCell>Include Page</TableCell>
                          <TableCell>Boolean</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.includePage}
                                  onChange={(e) => handleChangeCustom('includePage', e.target.checked)}
                                  name='includePage'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Title</TableCell>
                          <TableCell>Text</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='title'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell>Text</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='description'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>URL Link</TableCell>
                          <TableCell>Link</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='urlText'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      <TableRow>
                          <TableCell>Banner</TableCell>
                          <TableCell>Section</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.banner}
                                  onChange={(e) => handleChangeCustom( 'banner', e.target.checked)}
                                  name='banner'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                          {values.banner && (
                              <ImageUploadInput title='Banner Image' required fieldName={`bannerImage`} />
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='bannerOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                      <TableRow>
                          <TableCell>Headline</TableCell>
                          <TableCell>Section</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.headline}
                                  onChange={(e) => handleChangeCustom( 'headline', e.target.checked)}
                                  name='headline'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.headline && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='headlineText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='headlineOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Hours</TableCell>
                          <TableCell>Standard</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.hours}
                                  onChange={(e) => handleChangeCustom( 'hours', e.target.checked)}
                                  name='hours'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.hours && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='hoursText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='hoursOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Contact Details</TableCell>
                          <TableCell>Standard</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.contactDetails}
                                  onChange={(e) => handleChangeCustom( 'contactDetails', e.target.checked)}
                                  name='contactDetails'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.contactDetails && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='contactDetailsText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='contactDetailsOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Contact Form</TableCell>
                          <TableCell>Standard</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.contactForm}
                                  onChange={(e) => handleChangeCustom( 'contactForm', e.target.checked)}
                                  name='contactForm'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.contactForm && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='contactFormText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='contactFormOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Team</TableCell>
                          <TableCell>Standard</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.team}
                                  onChange={(e) => handleChangeCustom( 'team', e.target.checked)}
                                  name='team'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.team && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='teamText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='teamOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>HTML Module 1</TableCell>
                          <TableCell>HTML</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.htmlModule1}
                                  onChange={(e) => handleChangeCustom('htmlModule1', e.target.checked)}
                                  name='htmlModule1'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.htmlModule1 && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='htmlModule1Text'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='htmlModule1Order'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>HTML Module 2</TableCell>
                          <TableCell>HTML</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.htmlModule2}
                                  onChange={(e) => handleChangeCustom('htmlModule2', e.target.checked)}
                                  name='htmlModule2'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.htmlModule2 && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='htmlModule2Text'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='htmlModule2Order'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={2}>
                    <Button variant='contained' color='primary' type='submit'>
                      Save Changes
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ContactUsPage;
