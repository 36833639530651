import { Route } from 'react-router-dom';
import React, {  useContext } from 'react';
import { AuthContext } from './AuthContext';
import LoginComponent from '../../components/login/LoginComponent';
import DealerContextProvider from '../DealerContext';
import { IS_AUTHENTICATED } from '../Constants';

//Auth guard to protect global functionality from unauthorized access
function AuthGuard({ component: Component, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);
  const userAuth = localStorage.getItem(IS_AUTHENTICATED);

  return (
    <Route
      {...rest}
      render={(props) => 
        isAuthenticated || (userAuth !== false && userAuth !== null) ? (
          <DealerContextProvider>
          <Component {...props} />
          </DealerContextProvider>
        ) : (
          <LoginComponent/>
        )
       
      }
    />
  );
}

export default AuthGuard;