import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import { LoadingContext } from '../../shared/context/loadingContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TableHeadersSort from '../../shared/TableHeadersSort';
import { DEFAULT_PAGE_SIZE } from '../../shared/Constants';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';
import BuildsListFilter from './BuildsFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  stepPadding: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  green: {
    color: '#8bc34a'
  },
  hoverTableCell: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  removecategorybtn: {
    background: '#3f51b5',
    color: 'white',
    whiteSpace: 'nowrap',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default function BuildsTable() {
  const classes = useStyles();
  let history = useHistory();
  const [filteredBuilds, setFilteredBuilds] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('buildType');
  const [searchString, setSearchString] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [showDialog, setShowDialog] = useState({ show: false, activeDealer: null });
  const [isLoading, setIsLoading] = useState(true);
  const [debounceBuild, setDebounceBuild] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { showLoading, hideLoading } = useContext(LoadingContext)

  const { loading, builds, total } = BuildsListFilter(searchString, page, pageSize, order, orderBy);

  const [headCells, setHeadCells] = useState([
    { id: 'ID', label: 'Build no.', sort: true },
    { id: 'buildType', label: 'Type', sort: true },
    { id: 'websiteUrl', label: 'Site URL', sort: true },
    { id: 'status', label: 'Status', sort: true },
    { id: 'actionedBy', label: 'ActionedBy', sort: true },
    { id: 'createdAt', label: 'CreatedAt', sort: true },
    { id: 'updatedAt', label: 'UpdatedAt', sort: true }
  ]);

  useEffect(() => {
    if (loading) {
      return;
    }
    setFilteredBuilds(builds || []);

    // Set isLoading to false after processing.
    setIsLoading(false);
  }, [builds, loading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    setPage(0); // Set the page to 0 when Enter is pressed
    setSearchString(searchInput);
  };

  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let filterSearchBuild = filteredBuilds.filter(
    (a) => searchString === '' || a.dealerWebsite?.websiteUrl?.toLowerCase().includes(searchString.toLowerCase())
  );

  const handleBuildClick = (siteId) => {
    putBuild(siteId,{});
  };

  const putBuild = async (id, fields, resetForm) => {
    setDebounceBuild(true);
    showLoading();
    const result = await axios.put(`${BASE_URI}/websiteBuilds/triggerDirectBuilds/${id}`, fields).then((res)=>{
      enqueueSnackbar('build triggered', { variant: 'success' });
    }).catch((error)=>{
      enqueueSnackbar((error.message, 'Failed to trigger the build'), { variant: 'error' });
    }).finally(()=>{
      hideLoading();
      setDebounceBuild(false);
    })
}

  const formatDate = (date) => {
    let DateTimeFormat = new Date(date);
    return DateTimeFormat.getFullYear() ===1 ? '-' :new Intl.DateTimeFormat('en-US',{ dateStyle: 'medium', timeStyle: 'medium' }).format(new Date(date)).toLocaleLowerCase();
  }

  const formatStatus = (status) => {
    let statusArr = status.split('');
    statusArr[0] = statusArr[0].toUpperCase();
    return statusArr.join('');
}

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={4} className={classes.stepPadding} direction='column'>
          <Grid item xs={12}>
            <Toolbar disableGutters style={{ marginBottom: '2rem' }}>
              <Typography style={{ flex: '1 1' }} variant='h4' id='tableTitle' component='div'>
                Builds
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  name='buildFilter'
                  id='buildFilter'
                  label='Search Builds'
                  value={searchInput}
                  type='text'
                  InputProps={{
                    endAdornment: searchInput ? (
                      <InputAdornment position='end'>
                        <IconButton size='small' disableFocusRipple disableRipple onClick={() => setSearchInput('')}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : (
                      ''
                    )
                  }}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </form>

            </Toolbar>

            <TableContainer>
              <Table aria-label='users table' size='small'>
                <TableHeadersSort order={order} orderBy={orderBy} headCells={headCells} onRequestSort={handleRequestSort} />
                <TableBody>
                  {filterSearchBuild.map((row,index) => (
                      <TableRow hover className={classes.hoverTableCell} key={index}>
                      <TableCell>
                        <Typography
                          style={{ color: row.status === 'inActive' ? '#ff5722' : '' }}
                          variant="subtitle2"
                        >
                          {row.id}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {row.buildType}
                      </TableCell>
                      <TableCell style={{ wordWrap: 'break-word', whiteSpace: 'normal', maxWidth: '200px' }}>
                        {row.dealerWebsite.websiteUrl}
                      </TableCell>
                      <TableCell>
                        {formatStatus(row.status)}
                      </TableCell>
                      <TableCell>
                        {row.actionedBy}
                      </TableCell>
                      <TableCell>
                        {formatDate(row.createdAt)}
                      </TableCell>
                      <TableCell>
                        {formatDate(row.updatedAt)}
                      </TableCell>
                      <TableCell>
                        <Button
                          className={classes.removecategorybtn}
                          variant="contained"
                          color="primary"
                          onClick={() => handleBuildClick(row.dealerWebsiteId)}
                          disabled={debounceBuild}
                        >
                          Build
                        </Button>
                      </TableCell>
                    </TableRow>
                    
                  ))}
                  {!filteredBuilds.length && !isLoading && (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align='center' style={{ borderBottom: 0 }} className='py-3'>
                        <Typography variant='h6' color='textSecondary'>
                          No Builds Added
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}

                  {isLoading && (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align='center' style={{ borderBottom: 0 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                count={total}
                page={page}
                rowsPerPage={pageSize}
                component='div'
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangePageSize}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
