import React, { useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Grid,
  Button,
  Box,
  Switch,
  FormControlLabel,
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DealerContext } from '../../../shared/DealerContext';
import { useHistory, useParams } from 'react-router-dom';
import { TextField as TextFieldFormik } from 'formik-material-ui';
import { BASE_URI } from '../../../shared/Constants';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { LoadingContext } from '../../../shared/context/loadingContext';

const initialValues = {
  banner: false,
  welcomeHeadline: false,
  welcomeIntro: false,
  highlights: false,
  gallery: false,
  variants: false,

  headLineType: '',
  highlightType: '',
  galleryType: '',
  variantType: '',
  includePage: false,
  pageTitle: '',
  pageDescription: '',
  url: '',
  htmlModule1: false,
  htmlModule2: false,
  title: '',
  description: '',

  // Additional fields for multiline text areas
  bannerText: '',
  welcomeHeadlineText: '',
  welcomeIntroText: '',
  highlightsText: '',
  galleryText: '',
  variantsText: ''
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  dynamicComponentCell: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const NewVLP = (props) => {
  const { page } = props;
  const classes = useStyles();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const parsedObj = page?.templateConfig ? JSON.parse(page.templateConfig) : initialValues;
  const { selectedMotorGroup } = useContext(DealerContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  let history = useHistory();

  const headLineTypes = [
    { id: 1, name: 'Section' },
    { id: 2, name: 'Overlay' }
  ];

  const galleryTypes = [
    { id: 1, name: 'Scroll' },
    { id: 2, name: 'Grid' },
    { id: 3, name: 'Slider' }
  ];

  const highlightTypes = [
    { id: 1, name: 'Stacked' },
    { id: 2, name: 'Overlay' }
  ];

  const variantsTypes = [
    { id: 1, name: 'Scroll' },
    { id: 2, name: 'Slider' }
  ];

  const handleSelectChange = (e, setFieldValue, fieldName) => {
    setFieldValue(fieldName, e.target.value);
  };

  const onSubmitUpdatePage = async (fields) => {
    try {
      showLoading();
      fields.bobPayload = transformToBobPayload(fields);
      if (selectedMotorGroup.id) {
        if (page != undefined || page != null) {
          const pageId = page.id;
          page.templateConfig = JSON.stringify(fields);
          page.url = fields.url;
          page.title = fields.title;
          page.description = fields.description;
          page.content = fields.content;
          axios
            .put(`${BASE_URI}/WebsitePages/${pageId}`, {
              ...page,
              id: pageId
            })
            .then((result) => {
              enqueueSnackbar('Updated VLP page', { variant: 'success' });
              history.push('/templates');
              hideLoading();
            })
            .catch((error) => {
              enqueueSnackbar('Operation failed', { variant: 'error' });
              hideLoading();
            });
        } else {
          const newPage = {
            url: fields.url,
            title: fields.title,
            description: fields.description,
            content: '',
            templateConfig: JSON.stringify(fields),
            dealerID: selectedMotorGroup.id,
            websiteTemplatesId: parseInt(id),
            pageCategoryId: 2
          };
          axios
            .post(`${BASE_URI}/WebsitePages`, {
              ...newPage
            })
            .then((result) => {
              enqueueSnackbar('created VLP page', { variant: 'success' });
              hideLoading();
            })
            .catch((error) => {
              enqueueSnackbar('Operation failed', { variant: 'error' });
              hideLoading();
            });
        }
      } else {
        enqueueSnackbar('Select a dealer first', { variant: 'warning' });
        hideLoading();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Operation failed', { variant: 'error' });
      hideLoading();
    }
  };

  const transformToBobPayload = (values) => {
    const layoutMap = new Map();
  
    const addToLayoutMap = (partial, order, includes, params = []) => {
      if (includes || params.length > 0) {
        if (!layoutMap.has(partial)) {
          layoutMap.set(partial, { partial, order, includes, type: 'default', params });
        } else {
          const existing = layoutMap.get(partial);
          existing.includes = existing.includes || includes;
          existing.params = [...new Map([...existing.params, ...params].map(item => [item.key, item])).values()];
        }
      }
    };
  
    Object.keys(values).forEach((field) => {
      if (['includePage', 'title', 'description', 'url', 'bobPayload'].includes(field)) {
        return;
      }
  
      const includes = values[field] ? true : false;
      const order = values[`${field}Order`] || 0;
      let params = [];
  
      if (!field.endsWith('Text') && !field.endsWith('Order')) {
        if (values[`${field}Text`] !== undefined) {
          params.push({ key: `${field}Text`, value: values[`${field}Text`] });
        }
        addToLayoutMap(field, order, includes, params);
      }
    });
  
    const layout = Array.from(layoutMap.values());
  
    return {
      layout,
      page: page?.title || ''
    };
  };
  

  return (
    <Paper className={classes.paper}>
      <Toolbar>
        <Typography variant='h6' component='div'>
          New Vehicle Listing
        </Typography>
      </Toolbar>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Formik
            initialValues={parsedObj}
            onSubmit={(values) => {
              onSubmitUpdatePage(values);
            }}
          >
            {({ touched, values, errors, handleBlur, handleChange, setFieldValue }) => {
              const handleChangeCustom = (fieldName, checked) => {
                setFieldValue(fieldName, checked);
              };
              return (
                <Form>
                  <TableContainer className={classes.tableContainer}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Module</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Include</TableCell>
                          <TableCell>Content</TableCell>
                          <TableCell>Order</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Include Page</TableCell>
                          <TableCell>Boolean</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.includePage}
                                  onChange={(e) => handleChangeCustom('includePage', e.target.checked)}
                                  name='includePage'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Title</TableCell>
                          <TableCell>Text</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='title'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell>Text</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='description'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>URL Link</TableCell>
                          <TableCell>Link</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <div className={classes.dynamicComponentCell}>
                              <Field
                                name='urlText'
                                component={TextFieldFormik}
                                multiline
                                rows={4}
                                fullWidth
                                variant='outlined'
                                placeholder='Enter multiline text...'
                                onChange={handleChange}
                              />
                            </div>
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Banner</TableCell>
                          <TableCell>Standard</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.banner}
                                  onChange={(e) => handleChangeCustom('banner', e.target.checked)}
                                  name='banner'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.banner && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='bannerText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='bannerOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Welcome Headline</TableCell>
                          <TableCell>
                            <TextField
                              id='headLineType'
                              name='headLineType'
                              label='Headline'
                              value={values.headLineType}
                              select
                              fullWidth
                              helperText={errors.headLineType && touched.headLineType ? errors.headLineType : ''}
                              error={errors.headLineType && touched.headLineType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'headLineType')}
                            >
                              {headLineTypes.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.welcomeHeadline}
                                  onChange={(e) => handleChangeCustom('welcomeHeadline', e.target.checked)}
                                  name='welcomeHeadline'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.welcomeHeadline && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='welcomeHeadlineText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='welcomeHeadlineOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Welcome Intro</TableCell>
                          <TableCell>Section</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.welcomeIntro}
                                  onChange={(e) => handleChangeCustom('welcomeIntro', e.target.checked)}
                                  name='welcomeIntro'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.welcomeIntro && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='welcomeIntroText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='welcomeIntroOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Highlights</TableCell>
                          <TableCell>
                            <TextField
                              id='highlightType'
                              name='highlightType'
                              label='Highlight'
                              value={values.highlightType}
                              select
                              fullWidth
                              helperText={errors.highlightType && touched.highlightType ? errors.highlightType : ''}
                              error={errors.highlightType && touched.highlightType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'highlightType')}
                            >
                              {highlightTypes.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.highlights}
                                  onChange={(e) => handleChangeCustom('highlights', e.target.checked)}
                                  name='highlights'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.highlights && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='highlightsText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='highlightsOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Gallery</TableCell>
                          <TableCell>
                            <TextField
                              id='galleryType'
                              name='galleryType'
                              label='Gallery'
                              value={values.galleryType}
                              select
                              fullWidth
                              helperText={errors.galleryType && touched.galleryType ? errors.galleryType : ''}
                              error={errors.galleryType && touched.galleryType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'galleryType')}
                            >
                              {galleryTypes.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.gallery}
                                  onChange={(e) => handleChangeCustom('gallery', e.target.checked)}
                                  name='gallery'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.gallery && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='galleryText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='galleryOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>Trim/Variants</TableCell>
                          <TableCell>
                            <TextField
                              id='variantType'
                              name='variantType'
                              label='Variants'
                              value={values.variantType}
                              select
                              fullWidth
                              helperText={errors.variantType && touched.variantType ? errors.variantType : ''}
                              error={errors.variantType && touched.variantType}
                              onBlur={handleBlur}
                              onChange={(e) => handleSelectChange(e, setFieldValue, 'variantType')}
                            >
                              {variantsTypes.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.variants}
                                  onChange={(e) => handleChangeCustom('variants', e.target.checked)}
                                  name='variants'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.variants && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='variantsText'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='variantsOrder'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>HTML Module 1</TableCell>
                          <TableCell>HTML</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.htmlModule1}
                                  onChange={(e) => handleChangeCustom('htmlModule1', e.target.checked)}
                                  name='htmlModule1'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.htmlModule1 && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='htmlModule1Text'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='htmlModule1Order'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>HTML Module 2</TableCell>
                          <TableCell>HTML</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.htmlModule2}
                                  onChange={(e) => handleChangeCustom('htmlModule2', e.target.checked)}
                                  name='htmlModule2'
                                />
                              }
                              label=''
                            />
                          </TableCell>
                          <TableCell>
                            {values.htmlModule2 && (
                              <div className={classes.dynamicComponentCell}>
                                <Field
                                  name='htmlModule2Text'
                                  component={TextFieldFormik}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  variant='outlined'
                                  placeholder='Enter multiline text...'
                                  onChange={handleChange}
                                />
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name='htmlModule2Order'
                              type='number'
                              component={TextFieldFormik}
                              label='Order'
                              fullWidth
                              onChange={handleChange}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={2}>
                    <Button variant='contained' color='primary' type='submit'>
                      Save Changes
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NewVLP;
