import React, { createContext, useState } from 'react';

// Create the context
export const TemplateContext = createContext();

// Create a provider component
export const TemplateProvider = ({ children }) => {
  const [selectedOemTemplate, setSelectedOemTemplate] = useState(null);

  return (
    <TemplateContext.Provider value={{ selectedOemTemplate, setSelectedOemTemplate }}>
      {children}
    </TemplateContext.Provider>
  );
};
