import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import jwt from 'jwt-decode';
import CryptoJS from 'crypto-js'; 
import { useSnackbar } from 'notistack';
import { AUTH_USER, BASE_URI, AUTH_PERMISSION, IS_AUTHENTICATED, SESSION_KEY, ROLE_ADMIN } from '../Constants';

export const AuthContext = createContext({ role: '', permissions: [] });

export const AuthContextProvider = (props) => {
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [role, setRole] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [userDealerIDs, setUserDealerIDs] = useState([]);
    const [email, setEmail] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const [hasAdminRights, setHasAdminRights] = useState(false);
    const [userSession, setUserSession] = useState({});

    useEffect(() => {
        if (role === ROLE_ADMIN) {
            setHasAdminRights(true);
        }
    }, [role]);

    useEffect(() => {
        loadSessionFromLocalStorage();
    }, []);

    // Encrypt data
    const encryptData = (data) => {
        const jsonData = JSON.stringify(data);
        return CryptoJS.AES.encrypt(jsonData, SESSION_KEY).toString();
    };

    // Decrypt data
    const decryptData = (ciphertext) => {
        const bytes = CryptoJS.AES.decrypt(ciphertext, SESSION_KEY);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData); 
    };

    // Save session and permissions in localStorage
    const saveSessionToLocalStorage = (session, permissions) => {
        const encryptedSession = encryptData(session);
        const encryptedPermissions = encryptData(permissions);
        localStorage.setItem(AUTH_USER, encryptedSession);
        localStorage.setItem(AUTH_PERMISSION, encryptedPermissions);
        localStorage.setItem(IS_AUTHENTICATED, session.isAuthenticated);
    };

    // Load session and permissions from localStorage
    const loadSessionFromLocalStorage = () => {
        const encryptedSession = localStorage.getItem(AUTH_USER);
        const encryptedPermissions = localStorage.getItem(AUTH_PERMISSION);

        if (encryptedSession) {
            const decryptedSession = decryptData(encryptedSession);
            setUserSession(decryptedSession);
            setUserDealerIDs(decryptedSession.userDealerIDs || []);
            setRole(decryptedSession.role);
            setEmail(decryptedSession.email || '');
            setAuthenticated(true);

            if (encryptedPermissions) {
                const decryptedPermissions = decryptData(encryptedPermissions);
                setPermissions(decryptedPermissions);
            }

            checkTokenExpiration(decryptedSession)
        } else {
            logout();
        }
    };

    const setSession = (username, token, refreshToken, dealerIDs, permissions, name, isAuthenticated) => {
        const jwtHeader = jwt(token);
        const roles = jwtHeader['roles'];
        const user = {
            email: username || '',
            token: token,
            refreshToken: refreshToken,
            role: roles,
            userDealerIDs: dealerIDs,
            permissions: permissions,
            name: name,
            isAuthenticated: isAuthenticated
        };

        setUserDealerIDs(user.userDealerIDs);

        if (Array.isArray(roles)) {
            setRole([...roles]);
        } else {
            setRole(roles);
        }

        setEmail(user.email);
        setAuthenticated(true);
        setUserSession(user);
        saveSessionToLocalStorage(user, permissions);
    };
	
	// Axios request interceptor
    axios.interceptors.request.use(
        async (config) => {
            if (userSession && userSession.token) {
                const token = createJWTToken(userSession.token);  
                config.headers.authorization = token;
                config.maxContentLength = 100000000;
                config.maxBodyLength = 100000000;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // Axios response interceptor
    axios.interceptors.response.use(
        async (response) => response,
        async (error) => {
            if (axios.isAxiosError(error)) {
                const decryptedSession =  decryptData(localStorage.getItem(AUTH_USER));
                if(error?.response?.status===401 && decryptedSession){
                    enqueueSnackbar('Auth Token has expired, refresh the page to proceed or logout', { variant: 'info' });
                    checkTokenExpiration(decryptedSession); 
                }
            }
            throw error;
        }
    );

    // JWT token creation
    const createJWTToken = (token) => {
        return 'Bearer ' + token;
    };

    const checkTokenExpiration = (session) => {
        if (session) {
            const { token } = session;
            const decodedToken = jwt(token);
            const expirationTime = decodedToken.exp * 1000;
            if (Date.now() >= expirationTime) {
                logout(); // Logout on token expiration
            } else {
                setAuthenticated(true);
            }
        } else {
            console.error('Session does not exist');
        }
    };

    const logout = () => {
        localStorage.removeItem(AUTH_USER);
        localStorage.removeItem(AUTH_PERMISSION);
        localStorage.removeItem(IS_AUTHENTICATED);
        setUserSession({});
        setRole('');
        setPermissions([]);
        setAuthenticated(false);
    };

    const login = (username, password) => {
        return axios.post(`${BASE_URI}/User/Login`, {
            email: username,
            password,
        });
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, setSession, role, userDealerIDs, permissions, email, hasAdminRights, userSession }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
