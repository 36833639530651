import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI } from '../../shared/Constants';
import { DealerContext } from '../../shared/DealerContext';

export default function TemplateFormFilter(searchString, pageNumber, pageSize, order, orderBy) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [templateForms, setTemplateForms] = useState([]);
    const [total, setTotal] = useState(0);
    const { dealerId } = useContext(DealerContext);

    // Reset template forms when filters change
    useEffect(() => {
        setTemplateForms([]);
    }, [searchString, pageNumber, pageSize, order, orderBy, dealerId]);

    // Fetch data without debounce
    useEffect(() => {

            setError(null);

            const params = {
                pageNumber: pageNumber + 1,
                pageSize,
                dealerId
            };

            if (searchString) { 
                params.searchHeadline = searchString; 
            }

            if(dealerId!==""){
                setLoading(true)
                axios({
                    method: 'GET',
                    url: `${BASE_URI}/TemplateForm`,
                    params
                }).then((response) => {
                    if (response.data?.list) {
                        setTemplateForms(response.data?.list)
                        setTotal(response.data?.total)
                    }
                }).catch(error => {
                    if (axios.isCancel(error)) return
                    if (error.response) {
                        setError(true)
                    }
    
                }).then(()=>{
                    setLoading(false)
                })
            }

        // Clean up unnecessary API calls when dealerId is missing or other dependencies change
    }, [searchString, pageNumber, pageSize, order, orderBy, dealerId]);

    return { loading, error, templateForms, total };
}
