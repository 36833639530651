import React, { useEffect, useState, useContext } from 'react';
import { Grid, TextField, Button, Paper, MenuItem } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { BASE_URI } from '../../shared/Constants';
import { useSnackbar } from 'notistack';
import { DealerContext } from '../../shared/DealerContext';
import { LoadingContext } from '../../shared/context/loadingContext';

const useStyles = makeStyles((theme) => ({
  stepPadding: {
    padding: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  btn: {
    marginTop: theme.spacing(2)
  }
}));

export default function WebsiteSeo() {
  const classes = useStyles();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedMotorGroup } = useContext(DealerContext);
  const { showLoading, hideLoading } = useContext(LoadingContext);

  const [formFields, setFormFields] = useState({
    page: '',
    title: '',
    description: ''
  });
  const [pages, setPages] = useState([]);
  const [pageCategory, setPageCategory] = useState([]);

  useEffect(() => {
    // Fetch Pages and Page Categories
    const fetchData = async () => {
      showLoading();
      try {
        const pageResult = await axios.get(`${BASE_URI}/WebsitePages`, {
          params: { dealerId: selectedMotorGroup.id }
        });
        setPages(pageResult.data || []);

        const categoryResult = await axios.get(`${BASE_URI}/PartialComponent/pageCategory`);
        setPageCategory(categoryResult.data || []);
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'warning' });
      } finally {
        hideLoading();
      }
    };

    fetchData();
  }, [selectedMotorGroup.id, id]);

  useEffect(() => {
    // Update form fields when page selection changes
    if (formFields.page) {
      const selectedPage = pages.find((p) => p.id === formFields.page);
      if (selectedPage) {
        setFormFields({
          page: selectedPage.id,
          title: selectedPage.title || '',
          description: selectedPage.description || ''
        });
      }
    }
  }, [formFields.page, pages]);

  const onSubmit = async (fields) => {
    showLoading();
    try {
      const selectedPage = pages.find((p) => p.id === fields.page);
      if (!selectedPage) {
        enqueueSnackbar('Page not found', { variant: 'error' });
        return;
      }

      const updatedPage = {
        ...selectedPage,
        title: fields.title,
        description: fields.description,
        dealerId: selectedMotorGroup.id
      };

      await axios.put(`${BASE_URI}/WebsitePages/${updatedPage.id}`, updatedPage);
      enqueueSnackbar('SEO Information Saved', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Save failed', { variant: 'error' });
    } finally {
      hideLoading();
    }
  };

  return (
    <Paper className={classes.paper}>
      <Formik initialValues={formFields} onSubmit={onSubmit} enableReinitialize>
        {(props) => {
          const { values, handleChange, handleSubmit, setFieldValue } = props;

          const pagesWithCategoryNames = pages.map((page) => ({
            ...page,
            categoryName: pageCategory.find((cat) => cat.id === page.pageCategoryId)?.pageType || 'Unknown'
          }));

          const handlePageChange = (event) => {
            const pageId = event.target.value;
            setFieldValue('page', pageId);
            const selectedPage = pages.find((page) => page.id === pageId);
            if (selectedPage) {
              setFieldValue('title', selectedPage.title || '');
              setFieldValue('description', selectedPage.description || '');
            }
          };

          return (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={3} className={classes.stepPadding}>
                <Grid item xs={12}>
                  <Typography variant='h6' gutterBottom>
                    SEO Details
                  </Typography>
                </Grid>

                {/* Dropdown for Pages */}
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    fullWidth
                    name='page'
                    label='Select Page'
                    value={values.page}
                    onChange={handlePageChange}
                    variant='outlined'
                  >
                    {pagesWithCategoryNames.map((page) => (
                      <MenuItem key={page.id} value={page.id}>
                        {page.categoryName}
                      </MenuItem>
                    ))}
                  </TextField>
                  {/* Conditional rendering of Title and Description */}
                  {values.page && (
                    <>
                      <TextField fullWidth name='title' label='SEO Title' value={values.title} onChange={handleChange} variant='outlined' />

                      <TextField
                        fullWidth
                        name='description'
                        label='SEO Description'
                        value={values.description}
                        onChange={handleChange}
                        variant='outlined'
                      />
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Button type='submit' variant='contained' color='primary' className={classes.btn}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
}
