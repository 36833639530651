import React, { useContext, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { DealerContext } from './DealerContext';
import DealerListFilter from '../components/dealerAdmin/DealerListFilter';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { DEFAULT_PAGE_SIZE } from './Constants';
import { AuthContext } from './context/AuthContext';

const useStyles = makeStyles((theme) => ({
  white: {
    color: '#99AABF !important'
  },
  cssOutlinedInput: {
    '&$notchedOutline': {
      borderColor: `#99AABF !important`
    }
  },
  notchedOutline: {
    borderColor: '#99AABF !important'
  },
  select: {
    minWidth: '300px',
    maxWidth: '850px',
    width: '40%',
    fontFamily: '"SF Pro Text", sans-serif',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: '#99AABF',
    textTransform: 'none',
    textEdge: 'cap',
    wordBreak: 'break-word',
    WebkitFontSmoothing: 'antialiased'
  }
}));

export const DealerSelect = ({ label, className, secondary, disabled }) => {
  const classes = useStyles();
  const { selectedMotorGroup, updateSelectedMotorGroup } = useContext(DealerContext);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [searchString, setSearchString] = useState('');
  const { userSession } = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const { dealers } = DealerListFilter(searchString, page, pageSize);
  const userDealers = userSession?.userDealerIDs;
  const filteredDealers = dealers.filter((o) => userDealers.find((u) => u === o.id));

  const secondaryInputProps = {
    classes: {
      root: classes.cssOutlinedInput,
      notchedOutline: classes.notchedOutline,
      input: classes.white
    }
  };

  const secondaryLabelProps = {
    classes: {
      root: classes.white
    }
  };

  function handleDealerSelection(motorgroup) {
    if (motorgroup) {
      updateSelectedMotorGroup(motorgroup, motorgroup.id);
    } else {
      // Show the snackbar if no dealer is selected
      enqueueSnackbar('Please select a dealer!', { variant: 'warning' });
    }
  }

  function getOptionLabel(option) {
    let optionLabel = '';
    if (Object.entries(option).length !== 0) {
      optionLabel += !option.motorGroupName ? `No group - ${option.name}` : `${option.motorGroupName} - ${option.name}`;
    }

    return optionLabel;
  }

  useEffect(() => {
    enqueueSnackbar('Please select a dealer!', { variant: 'warning' });
  }, [enqueueSnackbar]);

  return (
    <React.Fragment>
      <Autocomplete
  id="dealerSelect"
  name="dealerSelect"
  fullWidth
  className={classes.select}
  value={selectedMotorGroup}
  options={filteredDealers}
  disabled={disabled}
  getOptionLabel={getOptionLabel}
  getOptionSelected={(option, value) => option.id === value?.id}
  onChange={(e, child) => handleDealerSelection(child)}
  renderInput={(params) => (
    <TextField
      {...params}
      className={className}
      label={label || "Select a Dealer"}
      placeholder="Search..."
      variant={secondary ? "outlined" : "standard"}
      InputLabelProps={{
        ...(secondary ? secondaryLabelProps : {}),
        shrink: true,
      }}
      InputProps={{
        ...params.InputProps,
        classes: secondary ? secondaryInputProps.classes : "",
        startAdornment: (
          <InputAdornment position="start" style={{ paddingLeft: "8px" }}>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <React.Fragment>
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }}
      onChange={(e) => setSearchString(e.target.value)}
    />
  )}
/>


    </React.Fragment>
  );
};
